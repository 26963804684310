(function() {
  'use strict';

  window.DS = {};


    define(getPropValue, 'base');
    define(id, 'id');
    define(cl, 'cl');
    define(childWithClass, 'childWithClass');
    define(getClosestParent, 'closestParent');
    define(tag, 'tag');
    define(eAddAll, 'eAddAll');
    define(eRmAll, 'eRmAll');
    define(addClass, 'addClass');
    define(removeClass, 'removeClass');
    define(toggleClass, 'toggleClass');
    define(query, 'query');
    define(getElementIndex, 'getElementIndex');
    define(getURLQueryVariables, 'getURLQueryVariables');
    define(validateEmail, 'validateEmail');
    define(ajax, 'ajax');
    define(loadImage, 'loadImage')
    define(debounce, 'debounce');
    define(fillVideo, 'fillVideo');
    define(urlPopup, 'urlPopup');
    define(createEvent, 'createEvent');
    define(getBackgroundImgURL, 'getBackgroundImgURL');


    function getPropValue(name) {
      return Object.getOwnPropertyDescriptor(window.DS, name).value;
    };

    function define(func, name) {
      return Object.defineProperty(window.DS, name, {
        value:func,
        enumerable: false,
        writable: false,
        configurable: false
      });
    };

    function id(id) {
      return document.getElementById(id);
    };

    function cl(classname, parent) {
     return parent ? parent.querySelectorAll('.' + classname) : document.getElementsByClassName(classname);
    };

    function tag(tag, parent) {
      return parent ? parent.querySelectorAll(tag) : document.getElementsByTagName(tag);
    };

    function query(selector, context) {
        //:-(
        context = context || document;
        if (/^(#?[\w-]+|\.[\w-.]+)$/.test(selector)) {
            switch (selector.charAt(0)) {
                case '#':
                    return [context.getElementById(selector.substr(1))];
                case '.':
                    var classes = selector.substr(1).replace(/\./g, ' ');
                    return [].slice.call(context.getElementsByClassName(classes));
                default:
                    return [].slice.call(context.getElementsByTagName(selector));
            }
        }
        return [].slice.call(context.querySelectorAll(selector));
    };

    function getElementIndex(node) {
        var index = 0;
        while ( (node = node.previousElementSibling) ) {
            index++;
        }
        return index;
    };


    function childWithClass(element, classname) {
      var ar = [];
      for (var i = 0; i < element.childNodes.length; i++) {
          if (doc.childNodes[i].className == classname) {
            ar.push(doc.childNodes[i]);

          }
      }
    };

    function eAddAll(object, type, listenFunction) {
      for(var i=0;i<object.length;i++) {
        object[i].addEventListener(type, listenFunction);
      }
    };

    function eRmAll(object, type, listenFunction) {
      for(var i=0;i<object.length;i++) {
        object[i].addEventListener(type, listenFunction);
      }
    };

    function addClass(element, classname) {
      if(element && element.length) {
        for(var i=0;i<element.length;i++) {
          element[i].classList.add(classname);
        }
      }

      else if(element) {
        element.classList.add(classname);
      }
    };

    function removeClass(element, classname) {

      if(element && element.length) {
        for(var i=0;i<element.length;i++) {
          element[i].classList.remove(classname);
        }
      }

      else if(element) {
        element.classList.remove(classname);
      }


    };

    function toggleClass(element, classname) {
      if(element.length) {
        for(var i=0;i<element.length;i++) {
          element[i].classList.contains(classname) ? element[i].classList.remove(classname) : element[i].classList.add(classname);
        }
      }

      else {
        element.classList.contains(classname) ? element.classList.remove(classname) : element.classList.add(classname);
      }
    };

    function getClosestParent ( elem, selector ) {
        // :-(
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function(s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) {}
                    return i > -1;
                };
        }
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
            if ( elem.matches( selector ) ) return elem;
        }
        return null;
    };

    function getURLQueryVariables(qstr) {
        var query,
            a;
        qstr = qstr.split('?');
        qstr = qstr[1];
        query = {};
        a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
        for (var i = 0; i < a.length; i++) {
            var b = a[i].split('=');
            query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
        }
        return query;
    };

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    function ajax(type, endpoint, data, callback) {
      var xhr = new XMLHttpRequest();
      var url = endpoint + "?data=" + encodeURIComponent(JSON.stringify(data));
      xhr.open(type, url, true);
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
              var json = JSON.parse(xhr.responseText);
              //console.log(json);
              return callback(json);
          }
      };
      xhr.send();
    };

    function loadImage(url, onload) {
      var img = new Image();
      img.src = url;
      img.onload = function() {
          //console.log('loaded ' + url);
          onload(img);
      };
      return img;
    };

    function debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    function fillVideo(vid, el) {
      var vWidth = vid.offsetWidth,
          vHeight = vid.offsetHeight,
          elWidth = el.offsetWidth,
          elHeight = el.offsetHeight,
          scaleWidth = elWidth / vWidth,
          scaleHeight = elHeight / vHeight,
          scale = scaleWidth > scaleHeight ? scaleWidth : scaleHeight;

          vid.style.width = (scale * vWidth) + 'px';
          vid.style.height = (scale * vHeight) + 'px';

    };

    function urlPopup(url, title, w, h) {
        // Fixes dual-screen position                         Most browsers      Firefox
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top = ((height / 2) - (h / 2)) + dualScreenTop;
        var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        // Puts focus on the newWindow
        if (window.focus) {
            newWindow.focus();
        }
    };

    function createEvent(eventName) {
        if(typeof(Event) === 'function') {
            var event = new Event(eventName);
            return event;
        }else{
            var event = document.createEvent('Event');
            event.initEvent(eventName, true, true);
            return event;
        }
    };

    function getBackgroundImgURL(element) {
      var img = element,
      style = img.currentStyle || window.getComputedStyle(img, false),
      bi = style.backgroundImage.slice(4, -1);

      // For IE we need to remove quotes to the proper url
      bi = style.backgroundImage.slice(4, -1).replace(/"/g, "");

      return bi;

    }




})();

