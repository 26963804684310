//"use strict";

/* LIBS */

//UNCOMMENT IF YOU NEED JQUERY & PASS JQUERY OBJECT TO FUNCTIONS TO USE
// var jquery = require('./lib/jquery.3.0.js');
// global.$ = global.jQuery = jquery;

//Gsap
require('./lib/TweenLite.js');
//require('./lib/TimelineLite.js');
//require('./lib/TimelineMax.js');
require('./lib/CSSPlugin.js');
require('./lib/gsap.scrollto.js');
require('./lib/ScrollMagic.js');
require('./lib/ScrollMagic.gsap.js');
require('./lib/ScrollMagic.indicators.js');
require('./lib/simple-scrollbar.js');

//require('./lib/CustomEase.js');

// vue

//require('./lib/vue.js');


/* SCRIPTS */

//utility

require('./utility/base.js');
require('./utility/form.js');
require('./utility/oldie.js');

//component

require('./component/header.js');
require('./component/footer.js');
require('./component/video.js');
//require('./component/carousel.js');
require('./component/press-carousel.js');
require('./component/minicart.js');
require('./component/newsletter.js');
require('./component/scrollbars.js');


//page


require('./page/home.js');
require('./page/plp.js');
require('./page/pdp.js');
require('./page/search.js');
require('./page/salon-detail.js');
require('./page/checkout.js');  //initialized via ajax loader callback on checkout page /Magento_Checkout/web/js/checkout-loader.js
require('./page/cart.js');
