(function() {
  'use strict';

  //Required base functions
  var base =  Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      loadImage = base('loadImage');




  window.DS.scrollbars = function() {
    var self = this;



    init();

    function init() {
      _setupScrollbars();

    };

    function _setupScrollbars() {
      var primaryNav = query('#header .primary-nav')[0],
          minicartScroller = query('.minicart-scroller')[0],
          salonScroller = query('.salon-sidebar')[0],
          textDetailsList = query('.pdp-details .text-details ul li .copy-wrap');



      SimpleScrollbar.initEl(primaryNav);
      SimpleScrollbar.initEl(minicartScroller);
      if(salonScroller) {
        SimpleScrollbar.initEl(salonScroller);
      }

      if(textDetailsList && textDetailsList[0]) {
        for(var i=0;i<textDetailsList.length;i++) {
          SimpleScrollbar.initEl(textDetailsList[i]);
        }
      }


    };







  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     console.log("scrollbars.js");
     var dsObject = new window.DS.scrollbars();

  });

  //});

})();
