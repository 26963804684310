(function() {
  'use strict';

  //Required base functions
  var base =  Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      closestParent = base('closestParent'),
      createEvent = base('createEvent');



  window.DS.header = function() {
    var self = this,
        header = id('header'),
        siteNav = query('.site-nav', header),
        siteNavLinks = query('.site-nav a', siteNav[0]),
        primaryNav = query('.primary-nav', header),
        headerLinks = query('.primary-nav a'),
        mblOpenClose = query('.mbl-open-close', header),
        primaryShopAccordionBtn = query('.primary-shop-accordion', header),
        toolbarLinks = query('#header .toolbar-link'),
        accountLink = query('#header .toolbar .account'),
        search = query('#header .toolbar .toolbar-search')[0],
        searchInput = query('.block-search')[0],
        searchInputElement = document.getElementById('search'),
        searchHovered = false,
        minicartLink = query('#header .toolbar .minicart'),
        iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
        is_safari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;


    init();

    function init() {
      _listen();
      _switchCategoriesPerSite();
      _plpOpenMenu();
      _routeHeaderPerBrand();
      _iosHeaderUpdate();
      _moveSearchBar();

    };

    function _listen() {
      //eAddAll(siteNavLinks, 'click', _switchSiteNav);
      eAddAll(headerLinks, 'click', _headerLinkClick);
      eAddAll(toolbarLinks, 'mouseenter', _toolbarLinkEnter);
      eAddAll(toolbarLinks, 'mouseleave', _toolbarLinkLeave);
      mblOpenClose[0].addEventListener('click', _openCloseMblNav);
      accountLink[0].addEventListener('touchstart', _accountOpener);
      accountLink[0].addEventListener('mouseenter', _accountHover);
      header.addEventListener('mouseleave', _closeAccountHover);
      minicartLink[0].addEventListener('mouseleave', _closeAccountHover);
      window.addEventListener('resize', _onResize);
      search.addEventListener('mouseenter', _searchOpen);
      header.addEventListener('mouseleave', _searchClose);
      search.addEventListener('click', _searchClickOpen);
      searchInputElement.addEventListener('focusout', _searchFocusOut);
    };

    function _searchClickOpen(e){
      e.preventDefault()
      if(mblOpenClose[0].clientHeight > 0){
        removeClass(searchInput, 'open');
        removeClass(search, 'active');
        _openCloseMblNav(e);
        toggleClass(query('.mbl-search'), 'active');
      }
    }

    function _searchOpen(e){
      searchHovered = true;
      if(mblOpenClose[0].clientHeight > 0){
        removeClass(searchInput, 'open');
        removeClass(search, 'active');
        // _openCloseMblNav(e);
        // toggleClass(query('.mbl-search'), 'active');
      } else if (mblOpenClose[0].clientHeight <= 0) {
        addClass(searchInput, 'open');
        addClass(search, 'active');
        _closeAccountHover();
        _closeMiniCart();
      }
    }

    function _searchFocusOut(e){
      if (!searchHovered) {
        _searchClose();
      }
    }

    function _searchClose(e){
      searchHovered = false;
      if (document.getElementById('search') !== document.activeElement) {
        if(mblOpenClose[0].clientHeight > 0){
          removeClass(searchInput, 'open');
          removeClass(search, 'active');
          // _openCloseMblNav(e);
          // toggleClass(query('.mbl-search'), 'active');
        } else if (mblOpenClose[0].clientHeight <= 0) {
          removeClass(searchInput, 'open');
          removeClass(search, 'active');
          removeClass(search, 'faded-open');
        }
      }
    }

    function _closeMiniCart(){
      var minicart = id('minicart'),
          minicartCurHeight = minicart.offsetHeight,
          minicartWrapper = query('.minicart-wrap', minicart),
          minicartHeight = minicartWrapper[0].offsetHeight;

      function rmStyle() {
        removeClass(minicart, 'moving');
        var minicartList = cl('minicart-scroller-list');

      };

      if(minicart.classList.contains('toggled')){
        TweenLite.fromTo(minicart, .26, {height:minicartHeight+'px'}, {height:'0px', onComplete:rmStyle});
        removeClass(query('#header .minicart'), 'open');
        removeClass(query('#header .account'), 'faded-open');
        removeClass(query('#header .toolbar-search'), 'faded-open');
        removeClass(minicart, 'toggled');
        id('maincontent').removeChild(id('minicart-big-hider'));
      }


    }

    // function _stopPropagation(e){
    //   e.stopPropagation();
    // }

    function _onResize(){
      _resizeOpenAccordions();
      _moveSearchBar();
    }

    function _moveSearchBar() {
      var mobileSearch = query('.mbl-search')[0];
      var toolbarMenu = query('.toolbar')[0];
      var firstToolbar = toolbarMenu.firstChild;
      if(mblOpenClose[0].clientHeight > 0 && !mobileSearch.contains(searchInput)){
        mobileSearch.appendChild(searchInput);
      } else if (mblOpenClose[0].clientHeight <= 0 && !toolbarMenu.contains(searchInput)) {
        toolbarMenu.insertBefore(searchInput, firstToolbar);
      }
    }

    function _resizeOpenAccordions() {
      var accordions = query('#header .primary-nav li.accordion');

      for(var i=0;i<accordions.length;i++) {
        _updateAccordion(accordions[i]);
      }
    };

    function _switchSiteNav(e) {
      e.preventDefault();

      var _this = this,
          _thisSite = _this.getAttribute('data-site');


      removeClass(siteNavLinks, 'active');
      addClass(_this, 'active');
      header.className = '';
      addClass(header, _thisSite);
      header.setAttribute('data-site', _thisSite);
      _switchCategoriesPerSite();

      return false;
    };

    function _switchCategoriesPerSite() {
      var site = header.getAttribute('data-site'),
          shopCategoryULs = query('.storenav', primaryNav[0]);

      removeClass(shopCategoryULs, 'active');
      for(var i=0;i<shopCategoryULs.length;i++) {
        var _thisSite = shopCategoryULs[i].getAttribute('data-shop');
        if(_thisSite===site){
          addClass(shopCategoryULs[i], 'active');

          if(primaryShopAccordionBtn[0].classList.contains('open')) {
            primaryShopAccordionBtn[0].firstElementChild.dispatchEvent(createEvent('click'));
          }

          break;
        }
      }

    };


    function _headerLinkClick(e) {
      var _this = this,
          shopAccLinks = query('#header .storenav.active li.accordion');
          e.preventDefault();

          //console.log(shopAccLinks);



      if(_this.parentElement.classList.contains('accordion')) {

          if(!_this.parentElement.classList.contains('open') && shopAccLinks.length) {


            for(var i=0;i<shopAccLinks.length;i++) {
              var closeOpenedAccEvent = createEvent('click');
              if(shopAccLinks[i].classList.contains('open')){
                shopAccLinks[i].children[0].dispatchEvent(closeOpenedAccEvent);
              }

            }

             toggleClass(_this.parentElement, 'open');
            _updateAccordion(_this.parentElement);

          }

          else {
            toggleClass(_this.parentElement, 'open');
            _updateAccordion(_this.parentElement);
          }





      }

      else {
        var loc = _this.getAttribute('href');
        console.log(loc);
        if(loc!=='' || loc!==undefined || loc===null) {
          window.location.href = loc;
        }



      }

      return false;


    };

    function _updateAccordion(element) {
      var elementOpen,
          ulChild = element.children[1],
          ulChildHidden = ulChild.children[0];

      function showShadowIfNavHigherThanViewport () {
        var nav = id('the-one-nav'),
            mainUL = nav.children[0];

        mainUL.offsetHeight>nav.offsetHeight ? addClass(nav, 'shadow') : removeClass(nav, 'shadow');


      };

      function checkForParent(el) {
        return el.parentElement.parentElement.parentElement.parentElement.parentElement;
      }

      function changeAccordion(el, dir, custTiming) {
        var ul = el.children[0],
            ulHeight = ulChildHidden.offsetHeight,
            parentAccordion = checkForParent(el),
            timing = .4;

        if(custTiming!==undefined) {
          timing = custTiming;
        }

        switch(dir) {
          case 'close':


            if(parentAccordion.classList.contains('accordion') && parentAccordion.classList.contains('open')) {

              TweenLite.set(ulChild, {height:'0px'});
              ulChild = parentAccordion.children[1];
              ulChildHidden = ulChild.children[0];
              changeAccordion(parentAccordion.children[1], 'open');

            }

            else {
              TweenLite.to(ulChild, timing, {height:'0px', ease:Power4.easeOut, onComplete:showShadowIfNavHigherThanViewport});
            }

            break;
          case 'open':

            if(parentAccordion.classList.contains('accordion') && parentAccordion.classList.contains('open')) {

              TweenLite.set(ulChild, {height:'auto'});
              ulChild = parentAccordion.children[1];
              ulChildHidden = ulChild.children[0];
              var childAccordions = query('.primary-shop-accordion .accordion');

              //console.log(childAccordions);
              // if(childAccordions.length){
              //   for(var i=0;i<childAccordions.length;i++) {
              //     console.log(childAccordions[i])
              //     console.log(parentAccordion);
              //     if(childAccordions[i].children[1] && childAccordions[i].children[1]!==ulChild.children[1]){
              //       changeAccordion(childAccordions[i].children[1], 'close', 0.0);

              //     }

              //   }
              // }

              changeAccordion(parentAccordion.children[1], 'open');

            }

            else {
              TweenLite.to(ulChild, timing, {height:ulHeight+'px', ease:Power4.easeOut, onComplete:showShadowIfNavHigherThanViewport});
            }
            break;
        }
      };


      element.classList.contains('open') ? changeAccordion(ulChild, 'open') : changeAccordion(ulChild, 'close');

    };


    function _openCloseMblNav(e) {
      e.preventDefault();

      var _this = this;

      toggleClass(mblOpenClose[0], 'open');
      if(primaryNav[0]){
        toggleClass(primaryNav[0], 'open');
      }
      toggleClass(query('.site-nav')[0], 'open');
      removeClass(query('.mbl-search'), 'active');

      return false;
    };

    function _plpOpenMenu() {
      var isPLP = query('.plp-category'),
          storeNav = query('.storenav li'),
          catPath = window.location.pathname;

      if(!iOS) {

        var shopClick = createEvent('click');
        var primaryShopNav = query('.primary-shop-accordion');
        primaryShopNav = primaryShopNav[0].children[0];


        primaryShopNav.dispatchEvent(shopClick);

        if(isPLP[0]) {

          for(var i=0;i<storeNav.length;i++) {
            var storeNavLink = storeNav[i].children[0].getAttribute('href');
            if(storeNavLink.indexOf(catPath) !== -1) {

              if(storeNav[i].classList.contains('level1')  && !is_safari) {
                var level1Event = createEvent('click');
                var trigLink = storeNav[i].parentElement.parentElement.parentElement.parentElement.children[0];

                trigLink.dispatchEvent(level1Event);



              }
              break;
            }

          }

        }


      }
    };


    function _routeHeaderPerBrand() {
      var headerRoute = id('header').getAttribute('class'),
          level1Lis = query('.one-mainnav')[0].children;

      var footerLis1 = query('#footer > ul > li:nth-child(2) a'),
          footerLis2 = query('#footer > ul > li:nth-child(3) a');


      function updateFooterLis(els) {
        for(var z=0;z<els.length;z++) {
          var chiildAnchorHref = els[z].getAttribute('href'),
          childAnchorHrefUpdate = chiildAnchorHref + '?brand=' + headerRoute;

          els[z].setAttribute('href', childAnchorHrefUpdate);
        }
      };

      updateFooterLis(footerLis1);
      updateFooterLis(footerLis2);


      //start at 3 to skip shop and mbl-account
      for(var i=3;i<level1Lis.length;i++) {
        var childAnchor = level1Lis[i].children[0],
            chiildAnchorHref = childAnchor.getAttribute('href'),
            childAnchorHrefUpdate = chiildAnchorHref + '?brand=' + headerRoute;

        childAnchor.setAttribute('href', childAnchorHrefUpdate);
      }

      //Toolbar Links + Checkout

      var accountLink = query('#header .toolbar-link.account');
      var accountLinkHref = accountLink[0].getAttribute('href');
      accountLink[0].setAttribute('href', accountLinkHref + '?brand=' + headerRoute);

      var miniCartCheckout = query('#header .minicart-checkout .cta');


      if(miniCartCheckout && miniCartCheckout[0]) {
         var miniCartCheckoutHref = miniCartCheckout[0].getAttribute('href');
         miniCartCheckout[0].setAttribute('href', miniCartCheckoutHref + '?brand=' + headerRoute);
      }

      var customerLogInOutLinks = query('#customer-log-in-out-panel a');

      if(customerLogInOutLinks && customerLogInOutLinks[0]) {
        for(var z=0;z<customerLogInOutLinks.length;z++) {
          var thisLinkHref = customerLogInOutLinks[z].getAttribute('href');
          customerLogInOutLinks[z].setAttribute('href', thisLinkHref + '?brand=' + headerRoute);

        }
      }

      var salonList = query('.salon-list a');

      if(salonList && salonList.length) {
        for(var x=0;x<salonList.length;x++) {
          var thisSalonHref = salonList[x].getAttribute('href');
          salonList[x].setAttribute('href', thisSalonHref + '?brand=' + headerRoute);
        }
      }


    };

    function _iosHeaderUpdate() {
      if(iOS) {
        addClass(document.body, 'ios-device');


        //Lil scroll position checker to hide/show borders when navbar covers screen

        var controller = new ScrollMagic.Controller();

        var scene = new ScrollMagic.Scene({
          triggerElement: "#maincontent",
          triggerHook: 0.0

        })
        .offset(68)
        //.addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
        .on('enter', function(e){
          addClass(query('#header .site-nav')[0], 'no-border');
        })
        .on('leave', function(){
          removeClass(query('.site-nav.open')[0], 'no-border');
        })
        .addTo(controller);


      }
    };

    function _toolbarLinkEnter(e) {
      var _this = this,
          type = _this.getAttribute('title');

      addClass(_this, 'hover');

      switch(type) {
        case 'View Account':
          addClass(query('#header .minicart'), 'faded');
          addClass(query('#header .toolbar-search'), 'faded');
          break;
        case 'View Cart':
          addClass(query('#header .account'), 'faded');
          addClass(query('#header .toolbar-search'), 'faded');
          break;
        case 'View Search':
          addClass(query('#header .account'), 'faded');
          addClass(query('#header .minicart'), 'faded');
          break;
      }
    };

    function _toolbarLinkLeave(e) {
      var _this = this;

      removeClass(toolbarLinks, 'hover');
      removeClass(toolbarLinks, 'faded');
    };

    function _accountOpener(e) {
      var _this = this,
          accountPanel = id('customer-log-in-out-panel'),
          windowWidth = window.innerWidth;

      if(accountPanel && windowWidth>750  && (windowWidth>window.innerHeight || windowWidth>1200) ) {
        e.preventDefault();
        toggleClass(_this, 'account-panel-open');
      }

      switch(_this.classList.contains('account-panel-open')) {
        case true:
          addClass(accountPanel, 'open');
          break;
        case false:
          removeClass(accountPanel, 'open');
          break;
      }

    };

    function _accountHover(e) {
      var _this = this,
          accountPanel = id('customer-log-in-out-panel'),
          windowWidth = window.innerWidth;

      if(accountPanel && windowWidth>750  && (windowWidth>window.innerHeight || windowWidth>1200) ) {
        e.preventDefault();
        addClass(_this, 'account-panel-open');
        addClass(accountPanel, 'open');
      }
      _searchClose(e);
      _closeMiniCart();

    };

    function _closeAccountHover(e) {
      var _this = this,
          accountPanel = id('customer-log-in-out-panel');


      removeClass(accountLink[0], 'account-panel-open');
      removeClass(accountPanel, 'open');
    };


  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     console.log("header.js");
     var dsObject = new window.DS.header();

  });

  //});

})();
