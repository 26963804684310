(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      closestParent = base('closestParent'),
      createEvent = base('createEvent');


  window.DS.form = function() {
    var _self = this;
        _self.listen = _listen;

    //Numerical Input buttons

    var numInputMinus = cl('num-minus'),
        numInputPlus = cl('num-plus'),
        qtyInput = cl('prod_qty');




    init();

    function init() {
      _listen();
    }

    function _listen() {
      eRmAll(numInputMinus, 'click', _changeNumberInput);
      eRmAll(numInputPlus, 'click', _changeNumberInput);
      eRmAll(qtyInput, 'click', _focusQty);
      eAddAll(numInputMinus, 'click', _changeNumberInput);
      eAddAll(numInputPlus, 'click', _changeNumberInput);
      eAddAll(qtyInput, 'click', _focusQty);
    }

    function _changeNumberInput(e) {
      e.preventDefault();

      var _this = this,
          inputChangeEvent = createEvent('change'),
          wrap = _this.parentElement,
          numberInput = wrap.querySelectorAll('.prod_qty'),
          numberValue = parseFloat(numberInput[0].value),
          numMin = parseFloat(numberInput[0].getAttribute('min')),
          numMax = parseFloat(numberInput[0].getAttribute('max')),
          numStep = parseFloat(numberInput[0].getAttribute('step'));

      //Check for product increment & add/subtract to value based on plus/minus click
      if(!isNaN(numStep)) {
        _this.classList.contains('num-plus') ? numberValue = numberValue + numStep : numberValue = numberValue - numStep;
      }

      else {
        _this.classList.contains('num-plus') ? numberValue++ : numberValue--;
      }

      //Check for min/max values; ignores if not defined
      if(!isNaN(numStep)) {
        numberValue<numMin ? numberValue = numStep : numberValue = numberValue;
      }

      else {
        numberValue<numMin ? numberValue = 1 : numberValue = numberValue;
      }

      numberValue>numMax ? numberValue = numMax : numberValue = numberValue;


      numberInput[0].value = numberValue;

      numberInput[0].dispatchEvent(inputChangeEvent);

      return false;
    }

    function _focusQty(e) {
      this.select();
    };






  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - and below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
    console.log("form.js");
    var dsObject = new window.DS.form();
    window.DS.form.listen = dsObject.listen;

  });

  //});

})();
