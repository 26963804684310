var onYouTubeIframeAPIReady;
(function() {
  'use strict';

  //Required base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      getURLQueryVariables = base('getURLQueryVariables');


  window.DS.video = function() {
    var self = this,
        videoBtns = cl('cta-video'),
        videoBtnsAlt = cl('cta-video-alt');



    var videoModalHTML = '';
    videoModalHTML +='<div id="video-modal">';
    videoModalHTML +='    <div class="bk"></div>';
    videoModalHTML +='    <div class="player" id="plaer">';
    videoModalHTML +='      <a href="" title="close" class="close">Close</a>'
    videoModalHTML +='      <div id="video-container">';
    videoModalHTML +='      </div>';
    videoModalHTML +='';
    videoModalHTML +='      <div class="controls">';
    videoModalHTML +='        <div class="playpause" title="Play/Pause">';
    videoModalHTML +='          <span class="play"></span>';
    videoModalHTML +='          <span class="pause"></span>';
    videoModalHTML +='        </div>';
    videoModalHTML +='        <div class="progress" title="Video Progress">';
    videoModalHTML +='          <span class="progress-time"><span class="progress-time-value">0:00</span></span>';
    videoModalHTML +='          <div class="progress-bar-wrapper">';
    videoModalHTML +='            <progress class="progress-bar" value="0" max="100"></progress>';
    videoModalHTML +='          </div>';
    videoModalHTML +='          <span class="total-time"><span class="progress-total-value">0:00</span></span>';
    videoModalHTML +='        </div>';
    videoModalHTML +='        <div class="volume">';
    videoModalHTML +='          <div class="on-off-region" title="Volume on/off"></div>';
    videoModalHTML +='          <div class="level-region" data-vol="100">';
    videoModalHTML +='            <div class="bar-1 volume-bar" title="Volume 33%"></div>';
    videoModalHTML +='            <div class="bar-2 volume-bar" title="Volume 66%"></div>';
    videoModalHTML +='            <div class="bar-3 volume-bar" title="Volume 100%"></div>';
    videoModalHTML +='          </div>';
    videoModalHTML +='        </div>';
    videoModalHTML +='        <div class="fullscreen" title="View fullscreen">';
    videoModalHTML +='';
    videoModalHTML +='          <div class="region-container">';
    videoModalHTML +='            <div class="region-tl"></div>';
    videoModalHTML +='            <div class="region-tr"></div>';
    videoModalHTML +='            <div class="region-bl"></div>';
    videoModalHTML +='            <div class="region-br"></div>';
    videoModalHTML +='';
    videoModalHTML +='          </div>';
    videoModalHTML +='        </div>';
    videoModalHTML +='      </div>';
    videoModalHTML +='    </div>';
    videoModalHTML +='  </div>';

    //console.log(videoModalHTML);

    init();

    function init() {
      _checkforVideos();

    }

    function _checkforVideos() {
      var ctas = query('.cta-video');

      if(ctas.length) {
        _initializeAPI();
        _listen();
      }


    };

    function _listen() {
      eAddAll(videoBtns, 'click', _buildVideoPlayer);
      eAddAll(videoBtnsAlt, 'click', _buildVideoPlayer);
    }

    function _initializeAPI() {
      var tag = document.createElement('script'),
          firstScriptTag = document.getElementsByTagName('script')[0];

      tag.setAttribute('id', 'youtube-scripts');
      tag.src = "https://www.youtube.com/iframe_api";
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };

    function _buildVideoPlayer(e) {
      e.preventDefault();

      var _this = this,
          videoURL = _this.getAttribute('data-video'),
          queryObject = getURLQueryVariables(videoURL),
          videoID = queryObject.v,
          player,
          videoProgressInterval,
          videoDuration,
          videoLength,
          curPercentage = 0;


          document.getElementById('vp-wrap').insertAdjacentHTML( 'beforeend', videoModalHTML );
          afterModalAppended();


          function afterModalAppended() {

            var videoModal = id('video-modal'),
                modalBk = query('#video-modal .bk'),
                closeBTN = query('.close', videoModal),
                playpauseBTN = query('.playpause', videoModal),
                curDurationHTML = query('.progress-time-value', videoModal),
                totalDurationHTML = query('.progress-total-value', videoModal),
                progressBarWrapper = query('.progress-bar-wrapper', videoModal),
                progressBar = query('.progress-bar', videoModal),
                soundOnOff = query('.on-off-region', videoModal),
                levelRegion = query('.level-region', videoModal),
                levelBars = query('.volume-bar', videoModal),
                videoPlayer = id('plaer'),
                iframeButton = query('.fullscreen');


            TweenLite.fromTo(modalBk[0], .6, {opacity:0}, {opacity:1, ease:Power4.easeOut});
            TweenLite.fromTo(videoPlayer, .4, {scaleX:.96, scaleY:.96, opacity:0}, {scaleX:1.0, scaleY:1.0, opacity:1, ease:Power4.easeOut});

            _createPlayer();

            function secToMinute(s){return(s-(s%=60))/60+(9<s?':':':0')+s}

            function videoPlayerListeners() {
              closeBTN[0].addEventListener('click', _closeVideoModal);
              modalBk[0].addEventListener('click', _closeVideoModal);
              playpauseBTN[0].addEventListener('click', _playPauseVideo);
              progressBarWrapper[0].addEventListener('click', _updateProgress);
              soundOnOff[0].addEventListener('click', _toggleSound);
              eAddAll(levelBars, 'click', _updateLevelByBar);
              iframeButton[0].addEventListener('click', _triggerFullscreen);
            };


            function _closeVideoModal(e) {
              e.preventDefault();

              closeBTN[0].removeEventListener('click', _closeVideoModal);
              modalBk[0].removeEventListener('click', _closeVideoModal);
              playpauseBTN[0].removeEventListener('click', _playPauseVideo);
              progressBarWrapper[0].removeEventListener('click', _updateProgress);
              soundOnOff[0].removeEventListener('click', _toggleSound);
              eRmAll(levelBars, 'click', _updateLevelByBar);
              iframeButton[0].removeEventListener('click', _triggerFullscreen);

              clearInterval(videoProgressInterval);
              videoProgressInterval = null;

              player.destroy();


              function rmModal() {id('vp-wrap').removeChild(videoModal);};

              TweenLite.fromTo(modalBk[0], .4, {opacity:1}, {opacity:0, ease:Power4.easeOut});
              TweenLite.fromTo(videoPlayer, .4, {opacity:1, scaleX:1.0, scaleY:1.0}, {opacity:0, scaleX:0.96, scaleY:0.96, ease:Power4.easeOut, onComplete:rmModal} );




              return false;
            };


            function _playPauseVideo(e) {
              e.preventDefault();

              var playerState = player.getPlayerState();

              switch(playerState) {
                case 1:
                  player.pauseVideo();
                  break;
                case 2:
                  player.playVideo();
                  break;
              }

              return false;
            };

            function _updateProgress(e) {
              var _this = this,
                  percentage = e.offsetX/_this.offsetWidth;

              player.seekTo(player.getDuration() * percentage);

            };

            function _toggleSound(e) {
              var _this = this,
                  curVol = player.getVolume();

                if(curVol>0) {
                  player.setVolume(0);
                  levelRegion[0].setAttribute('data-vol', 0);
                }

                else {
                  levelRegion[0].setAttribute('data-vol', 100);
                  player.setVolume(100);
                }

            };


            function _updateLevelByBar(e) {
              var _this = this;


              if(_this.classList.contains('bar-1')) {
                player.setVolume(33);
                levelRegion[0].setAttribute('data-vol', 33);
              }

              else if(_this.classList.contains('bar-2')) {
                player.setVolume(66);
                levelRegion[0].setAttribute('data-vol', 66);
              }

              else if(_this.classList.contains('bar-3')) {
                player.setVolume(100);
                levelRegion[0].setAttribute('data-vol', 100);
              }
            };



            function _triggerFullscreen(e) {
                var iframe = videoPlayer;
                var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen || iframe.msRequestFullscreen;
                var exitFullScreen = iframe.exitFullscreen || iframe.webkitExitFullscreen || iframe.mozCancelFullScreen || iframe.msExitFullscreen;
                var fullscreenEnabled = iframe.fullscreenEnabled || iframe.webkitFullscreenEnabled || iframe.mozFullScreenEnabled || iframe.msFullscreenEnabled;
                var docFS = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

                //iframe.classList.contains('fullscreen') ? isFS = true : isFS = false;

                iframe.addEventListener("fullscreenchange", fullScreenOnEsc);
                iframe.addEventListener("webkitfullscreenchange", fullScreenOnEsc);
                iframe.addEventListener("mozfullscreenchange", fullScreenOnEsc);
                iframe.addEventListener("MSFullscreenChange", fullScreenOnEsc);

                if(document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullscreenElement) {

                    if (document.exitFullscreen) {
                      document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                      document.webkitExitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                      document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                      document.msExitFullscreen();
                    }



                }

                else {
                  if (requestFullScreen) {
                      requestFullScreen.bind(iframe)();
                      //console.log('enter click');
                  }
                }







                function fullScreenOnEsc() {


                  if(document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement ||
                    document.msFullscreenElement) {
                    addClass(iframe, 'fullscreen');
                  }

                  else {
                    removeClass(iframe, 'fullscreen');
                  }
                };



            };



           function _createPlayer() {
              player = new YT.Player('video-container', {
                height: '100%',
                width: '100%',
                videoId: videoID,
                playerVars: {
                  controls:0,
                  enablejsapi:1,
                  modestbranding:1,
                  showinfo:0,
                  rel: 0
                },
                events: {
                  'onReady': onPlayerReady,
                  'onStateChange': onPlayerStateChange
                }
              });
            }

            function onPlayerReady(event) {

              videoPlayerListeners();
              videoLength = secToMinute(player.getDuration());
              totalDurationHTML[0].innerHTML = videoLength;
              event.target.playVideo();

            }


            function onPlayerStateChange(event) {



              switch(event.data) {
                case 0:
                  //ENDED
                  removeClass(playpauseBTN, 'playing');
                  clearInterval(videoProgressInterval);
                  videoProgressInterval = null;
                  break;
                case 1:
                  //PLAYING
                  addClass(playpauseBTN, 'playing');
                  videoProgressInterval = setInterval(_checkVideoProgress, 500);
                  break;
                case 2:
                  //PAUSED
                  removeClass(playpauseBTN, 'playing');
                  clearInterval(videoProgressInterval);
                  videoProgressInterval = null;
                  break;
                case 3:
                  //BUFFERING
                  break;
                case -1:
                  //UNSTARTED
                  break;
                case 5:
                  //CUED
                    break;
              }
            }


            function _checkVideoProgress() {
              var curTime = secToMinute(Math.floor(player.getCurrentTime())),
                  totalPercentage = Math.ceil((player.getCurrentTime()/player.getDuration())*100);

              curDurationHTML[0].innerHTML = curTime;

              if(curPercentage!==totalPercentage) {
                curPercentage = totalPercentage;
                progressBar[0].setAttribute('value', curPercentage);
              }


            }
          };



      return false;

    }






  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     console.log("video.js");
     var dsObject = new window.DS.video();

  });

  //});

})();
