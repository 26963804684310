(function() {
  //'use strict';

  //Required base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      ajax = base('ajax'),
      debounce = base('debounce');


  window.DS.minicart = function() {
    var _self = this,
        //Minicart Els
        minicartBTN = query('.toolbar-link.minicart'),
        minicart = id('minicart'),
        minicartWrapper = query('.minicart-wrap', minicart),
        minicartList = cl('minicart-scroller-list'),
        minicartLis = query('li', minicartList[0]),
        subtotal = query('.subtotal-value', minicart),
        minicartInputs = query('.prod_qty', minicartList[0]),
        minicartRemoveButtons = query('.remove', minicartList[0]),
        formKeyValue = document.querySelector('input[name="form_key"]'),
        cartAddButtons = cl('cart-add'),
        minicartUpdateInterval,
        //Cart page Els
        cartPageWrap = cl('.fek-cart-wrap'),
        cartList = query('.cart-list-page', cartPageWrap[0]),
        cartRemoveButton = query('.remove-link-cart', cartPageWrap[0]),
        cartItemInputs = query('.prod_qty', cartPageWrap[0]),
        cartSubtotalEl = query('.subtotal-change-value', cartPageWrap[0]),
        cartDiscountEl = query('.discount-change-value', cartPageWrap[0]),
        cartGrandtotalEl = query('.grandtotal-change-value'),
        addFavTileButtons = query('.add-fav-tile'),
        pdpButton = query('.cart-add.pdp-qty');






    init();

    function init() {
      _listen();
     _getMiniCartData();

    }

    function _listen() {
      //move - reinitialize changing DOM values
      minicartInputs = query('.prod_qty', minicartList[0]);
      minicartLis = query('li', minicartList[0]);
      minicartList = cl('minicart-scroller-list');
      minicartRemoveButtons = query('.remove', minicartList[0]);
      cartItemInputs = query('.prod_qty', cartPageWrap[0]);
      cartRemoveButton = query('.remove-link-cart', cartPageWrap[0]);

      minicartBTN[0].removeEventListener('click', _clickMinicart);
      minicartBTN[0].addEventListener('mouseenter', _openCloseMinicart);
      // minicartBTN[0].addEventListener('mouseleave', _openCloseMinicart);


      eRmAll(minicartInputs, 'change', _updateMinicartSubtotal);
      eAddAll(minicartInputs, 'change', _updateMinicartSubtotal);

      eRmAll(cartAddButtons, 'click', _addToCart);
      eAddAll(cartAddButtons, 'click', _addToCart);

      eRmAll(minicartRemoveButtons, 'click', _removeItemFromCart);
      eAddAll(minicartRemoveButtons, 'click', _removeItemFromCart);

      eRmAll(cartRemoveButton, 'click', _cartPageRemoveItem);
      eAddAll(cartRemoveButton, 'click', _cartPageRemoveItem);

      eRmAll(cartItemInputs, 'change', _cartPageUpdateItem);
      eAddAll(cartItemInputs, 'change', _cartPageUpdateItem);

      eRmAll(addFavTileButtons, 'click', _addItemToFavs);
      eAddAll(addFavTileButtons, 'click', _addItemToFavs);

      window.removeEventListener('resize', _adjustMiniCartHeight);
      window.addEventListener('resize', _adjustMiniCartHeight);



    }

    function _clickMinicart(e){
      if(window.innerWidth<751) {
        window.location.pathname = '/checkout/cart/';
      }
    }

    function _openCloseMinicart(e) {

      if(window.innerWidth>=751) {
        e.preventDefault();


        if(!minicart.classList.contains('moving')) {

          var minicartCurHeight = minicart.offsetHeight,
              minicartHeight = minicartWrapper[0].offsetHeight,
              emptyLis = query('.minicart-scroller-list li.empty');
          addClass(minicart, 'moving');
          toggleClass(minicart, 'toggled');

          if(emptyLis && emptyLis.length>0){
            removeClass(minicart, 'filled');
          }

          else {
            addClass(minicart, 'filled');
          }



          function rmStyle() {
            removeClass(minicart, 'moving');
            minicartList = cl('minicart-scroller-list');

          };


          //if(minicartCurHeight===minicartHeight) {
          if(minicart.classList.contains('toggled')) {
            TweenLite.fromTo(minicart, .26, {height:'0px'}, {height:minicartHeight+'px', onComplete:rmStyle});
            addClass(query('#header .minicart'), 'open');
            addClass(query('#header .account'), 'faded-open');
            addClass(query('#header .toolbar-search'), 'faded-open');

            var miniCartBigClick = document.createElement('div');
            miniCartBigClick.id = 'minicart-big-hider';
            id('maincontent').appendChild(miniCartBigClick);
            id('minicart-big-hider').addEventListener('click', _openCloseMinicart);

          }

          else {
            TweenLite.fromTo(minicart, .26, {height:minicartHeight+'px'}, {height:'0px', onComplete:rmStyle});
            removeClass(query('#header .minicart'), 'open');
            removeClass(query('#header .account'), 'faded-open');
            removeClass(query('#header .toolbar-search'), 'faded-open');
            id('maincontent').removeChild(id('minicart-big-hider'));

          }


        }

        return false;
      }



    };


    function _adjustMiniCartHeight() {

        var minicartCurHeight = minicart.offsetHeight;
        var minicartHeight = minicartWrapper[0].offsetHeight;


        if(!minicart.classList.contains('toggled')) {
          return false;
          addClass(minicart, 'toggled');
        }



        if(id('minicart-big-hider')===null) {
          var miniCartBigClick = document.createElement('div');
          miniCartBigClick.id = 'minicart-big-hider';
          id('maincontent').appendChild(miniCartBigClick);
          id('minicart-big-hider').addEventListener('click', _openCloseMinicart);
        }

        function rmStyle() {
          //console.log(minicartLis.length);
          minicartList = cl('minicart-scroller-list');
          if(query('li.empty', minicartList[0]).length>0){
           removeClass(minicart, 'filled');
          }
          else {

            //_updateMinicartSubtotal();
            addClass(minicart, 'filled');
          }
        };

        TweenLite.to(minicart, .4, {height:minicartHeight+'px', onComplete:rmStyle});


    };

    var _updateMinicartSubtotal = debounce(function() {
      var total = 0;

      var idQty = [];

      for(var i=0;i<minicartLis.length;i++) {
        var _li = minicartLis[i],
            id = _li.getAttribute('data-id'),
            price = query('.price', _li),
            qty = query('.prod_qty', _li),
            price;



        price = price[0].innerHTML;
        price = price.replace('$', '');
        price = parseFloat(price);

        qty = parseFloat(qty[0].value);

        price = qty * price;

        total+=price;

        idQty[i] = [id, qty];


      }


      subtotal[0].innerHTML = '$' + total.toFixed(2);


      ajax('POST', '/fekkaicart/Ajax/UpdateCart', {'form_key':formKeyValue.value, 'updates' : idQty}, _getMiniCartData);
    }, 1000);




    function _addToCart(e) {
      e.preventDefault();

      var _this = this,
          id = this.getAttribute('data-id'),
          qty = 1;

      //Qty on pdp can vary

      if(_this.classList.contains('pdp-qty')) {
        var pdpHero = query('.pdp-hero'),
            pdpQtyInput = query('.prod_qty', pdpHero[0]),
            newQty = pdpQtyInput[0].value;
            qty = newQty;
      }

      //console.log(pdpButton[0]);

      if(pdpButton[0]) {
        pdpButton[0].setAttribute('value', 'Adding');
      }

      addClass(minicart, 'toggled');

      ajax('POST', '/fekkaicart/Ajax/AddCart', {'form_key':formKeyValue.value, 'id' : id, 'qty' : qty}, _getMiniCartData);


      return false;
    };


    var _removeItemFromCart = debounce(function(e) {
      e.preventDefault();

      var _this = this,
          parentLI = _this.parentElement.parentElement.parentElement,
          itemID = parentLI.getAttribute('data-id'),
          idQty = [];

      idQty[0] = [itemID, 0];

      ajax('POST', '/fekkaicart/Ajax/UpdateCart', {'form_key':formKeyValue.value, 'updates' : idQty}, _getMiniCartData);

      return false;

    }, 500);


    var updateCount = 0;

    function _updateMiniCartHTML(json) {

      minicartList[0].innerHTML = '';
      updateCount++;

      var itemsHTML = '';

      if(json.cart.itemcount<1 || json.cart.itemcount===null) {

        itemsHTML += '<li class="empty"><p>Your cart is empty</p></li>';
        removeClass(minicartBTN[0], 'filled');
        query('.fek-minicart-counter')[0].textContent = '';
      }

      else {

        for(var i=0;i<json.cart.items.length;i++) {
          var item = json.cart.items[i];

          var min = '1',
              max = item.data.stockInfo.max_sale_qty,
              step = '';

          //If max qty is less than inventory, default to inventory max
          if(item.data.stockInfo.qty<max) {
            max = item.data.stockInfo.qty;
          }

          if(item.data.stockInfo.use_config_qty_increments==='0' && item.data.stockInfo.enable_qty_increments==='1') {
            min = item.data.stockInfo.qty_increments;
            step = item.data.stockInfo.qty_increments;
          }

          else if (item.data.stockInfo.use_config_min_qty==='0') {
            min = item.data.stockInfo.min_qty;
          }


          itemsHTML += '<li data-id="'+item.id+'">';
          itemsHTML +=    '<div class="product-img" style="background-image:url('+item.data.image+');" title="'+item.name+'"></div>';
          itemsHTML +=    '<div class="product-details">';
          itemsHTML +=      '<p>'+item.data.tagline+'</p>';
          itemsHTML +=      '<p><strong>'+item.name+'</strong></p>';
          if (item.data.messages.length > 0) {
            itemsHTML +='    <div class="item-msgs">';
            for (var j=0; j<item.data.messages.length; j++) {
              itemsHTML += '<p class="item-msg">'+item.data.messages[j]+'</p>';
            }
            itemsHTML +='    </div>';
          }
          itemsHTML +=      '<div class="item-functions">';
          itemsHTML +=        '<div class="number-wrap">';
          itemsHTML +=          '<input title="Enter Order Quantity" type="number" min="'+min+'" max="'+max+'" step="'+step+'" class="prod_qty" name="prod_qty" value="'+item.qty+'">';
          itemsHTML +=          '<a href="" class="num-minus" title="Decrease"></a>';
          itemsHTML +=          '<a href="" class="num-plus" title="Increase"></a>';
          itemsHTML +=        '</div>';
          itemsHTML +=        '<span class="remove">Remove</span>';
          itemsHTML +=        '<div class="price-details">';
          itemsHTML +=          '<span class="price" title="Price per unit">'+item.data.final_price+'</span>';
          itemsHTML +=          '<span class="size" title="Unit Size">/ '+item.data.display_weight+'</span>';
          itemsHTML +=        '</div>';
          itemsHTML +=    '</div>';
          itemsHTML += '</li>';
        }

        subtotal[0].innerHTML = json.cart.subtotal;

        addClass(minicartBTN[0], 'filled');
        query('.fek-minicart-counter')[0].textContent = Math.round(json.cart.itemcount);
      }


      minicartList[0].innerHTML = itemsHTML;


      if(cartRemoveButton.length>0) {
        //is Cart page
        //refresh if empty cart
        if(json.cart.itemcount<1 || json.cart.itemcount===null) {
          window.location = window.location;
        }
        else {
          _updateCartPageHTML(json);
        }

      }

      //refresh if change made on checkout
      if(document.body.classList.contains('checkout-index-index') && updateCount!==1) {
        window.location = window.location;
      }


      setTimeout(function(){

        _listen();
        DS.form.listen();
        //console.log(query('li.empty', minicartList[0]).length);
        if(query('li.empty', minicartList[0]).length>0){
         removeClass(minicart, 'filled');
         _adjustMiniCartHeight();
        }
        else {

          addClass(minicart, 'filled');
          _adjustMiniCartHeight();
        }
      }, 0);


      if(pdpButton[0]) {
        pdpButton[0].setAttribute('value', 'Add to Cart');
      }





    };


    function _getMiniCartData() {
       ajax('POST', '/fekkaicart/Ajax/GetCart', {'form_key':formKeyValue.value}, _updateMiniCartHTML);
    };



    function _cartPageUpdateItem(e) {
      var _this = this,
          qty = _this.value,
          itemID = _this.getAttribute('data-item-id'),
          idQty = [];

          idQty[0] = [itemID, qty];

          if(this.classList.contains('prod_qty_cart')) {
            ajax('POST', '/fekkaicart/Ajax/UpdateCart', {'form_key':formKeyValue.value, 'updates' : idQty}, _getMiniCartData);
          }


    };

    function _cartPageRemoveItem(e) {
      e.preventDefault();

      var _this = this,
          qty = 0,
          itemID = _this.getAttribute('data-item-id'),
          idQty = [];

          idQty[0] = [itemID, qty];



          ajax('POST', '/fekkaicart/Ajax/UpdateCart', {'form_key':formKeyValue.value, 'updates' : idQty}, _getMiniCartData);

      return false;
    };

    function _updateCartPageHTML(json) {
        var itemsHTML = '';

        cartSubtotalEl[0].textContent = json.cart.subtotal;
        cartGrandtotalEl[0].textContent = json.cart.grandtotal;

        if(json.cart.discount) {
          cartDiscountEl[0].textContent = '-' + json.cart.discount;
        }

        for(var i=0;i<json.cart.items.length;i++) {
          var item = json.cart.items[i];

          var min = '1',
              max = item.data.stockInfo.max_sale_qty,
              step = '';

          //If max qty is less than inventory, default to inventory max
          if(item.data.stockInfo.qty<max) {
            max = item.data.stockInfo.qty;
          }

          if(item.data.stockInfo.use_config_qty_increments==='0' && item.data.stockInfo.enable_qty_increments==='1') {
            min = item.data.stockInfo.qty_increments;
            step = item.data.stockInfo.qty_increments;
          }

          else if (item.data.stockInfo.use_config_min_qty==='0') {
            min = item.data.stockInfo.min_qty;
          }

          itemsHTML +='<li>';
          itemsHTML +='  <a href="'+item.data.url+'" class="item-img" style="background-image:url('+item.data.image+')"></a>';
          itemsHTML +='  <div class="item-description">';
          itemsHTML +='    <p class="item-tagline">'+item.data.tagline+'</p>';
          itemsHTML +='    <p class="item-name"><strong>'+item.name+'</strong></p>';
          itemsHTML +='    <p class="item-size">'+item.data.display_weight+'</p>';
          if (item.data.messages.length > 0) {
            itemsHTML +='    <div class="item-msgs">';
            for (var j=0; j<item.data.messages.length; j++) {
              itemsHTML += '<p class="item-msg">'+item.data.messages[j]+'</p>';
            }
            itemsHTML +='    </div>';
          }
          itemsHTML +='    <a href="" data-item-id="'+item.id+'" class="remove-link-cart">Remove</a>';
          itemsHTML +='  </div>';
          itemsHTML +='  <div class="item-price">';
          itemsHTML +='    <span class="price">'+item.data.final_price+'</span>          </div>';
          itemsHTML +='  <div class="functional-block qty">';
          itemsHTML +='    <input data-item-id="'+item.id+'" title="Enter Order Quantity" type="number" min="'+min+'" max="'+max+'" step="'+step+'" class="prod_qty prod_qty_cart" name="qty" value="'+item.qty+'">';
          itemsHTML +='    ';
          itemsHTML +='    <a href="" class="num-minus" title="Decrease"></a>';
          itemsHTML +='    <a href="" class="num-plus" title="Increase"></a>';
          itemsHTML +='  </div>';
          itemsHTML +='</li>';

        }


        cartList[0].innerHTML = itemsHTML;

        setTimeout(function(){

          _listen();
          DS.form.listen();

        }, 0);
    };

    function _addItemToFavs(e) {
      e.preventDefault();

      var _this = this,
          selectedId = this.getAttribute('data-product-id');



      function _addedToFavorites(json) {
        window.location.pathname = '/wishlist/';
      };

      ajax('POST', '/fekkaicustomer/Ajax/AddWishlistItem', {'form_key':formKeyValue.value, 'id' : selectedId}, _addedToFavorites);


      return false;
    };



  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     console.log("minicart.js");
     var dsObject = new window.DS.minicart();

  });

  //});

})();
