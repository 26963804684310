(function() {
  'use strict';

  //Required base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      validateEmail = base('validateEmail');

  window.DS.footer = function() {
    var self = this,
        footerSections = query('#footer > ul > li');

    init();

    function init() {
      _listen();
    }

    function _listen() {
      eAddAll(footerSections, 'click', _toggleSectionOpen);
    }

    function _toggleSectionOpen(e) {
      toggleClass(this, 'open');
    };
  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     console.log("footer.js");
     var dsObject = new window.DS.footer();

  });

  //});

})();
