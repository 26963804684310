(function() {
  'use strict';

  //Required base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      validateEmail = base('validateEmail'),
      ajax = base('ajax');


  window.DS.newsletter = function() {
    var self = this,
        formKeyValue = document.querySelector('input[name="form_key"]'),
        newsletterSignup = query('.newsletter-signup'),
        newsletterModal = query('.newsletter-modal-trigger');



    init();

    function init() {
      _listen();
    }

    function _listen() {
      eRmAll(newsletterSignup, 'submit', _newsletterSubscribe);
      eAddAll(newsletterSignup, 'submit', _newsletterSubscribe);
      eRmAll(newsletterModal, 'click', _buildNewsletterModal);
      eAddAll(newsletterModal, 'click', _buildNewsletterModal);

    }


    function _newsletterSubscribe(e) {
      e.preventDefault();

      var _this = this,
          emailInput = query('.newsletter-input', _this),
          emailVal = emailInput[0].value,
          isValid = validateEmail(emailVal);

      isValid === false ? addClass(emailInput[0], 'error') : removeClass(emailInput[0], 'error');

      if(isValid===true) {
        //submit email
        ajax('POST', '/fekkaipage/Ajax/Mail', {'form_key':formKeyValue.value, 'email':emailVal}, _newsletterSuccess);
        emailInput[0].value = 'Thank you';
        emailInput[0].disabled = true;

      }


      return false;
    };


    function _newsletterSuccess(data) {

    };


    function _buildNewsletterModal(e) {
      e.preventDefault();

      var modalHTML = '';
      modalHTML +='<aside class="newsletter-signup-modal">';
      modalHTML +=' <div class="fade-bk"></div>';
      modalHTML +=' <div class="inner-container">';
      modalHTML +='   <a href="" title="close" class="close">Close</a>';
      modalHTML +='   <h2>Sign up for our newsletter</h2>';
      modalHTML +='   <p>Get on the list and get insider access to events, invites, news, sales and special offers.</p>';
      modalHTML +='   <form class="newsletter-signup">';
      modalHTML +='         <input type="text" placeholder="Email Address" name="newsletter_signup" class="newsletter-input">';
      modalHTML +='         <input type="submit" title="SUBMIT" value="b">';
      modalHTML +='     </form>';
      modalHTML +=' </div>';
      modalHTML +='</aside>';

      document.getElementById('vp-wrap').insertAdjacentHTML( 'beforeend', modalHTML );


      TweenLite.fromTo(query('.newsletter-signup-modal .fade-bk')[0], .6, {opacity:0}, {opacity:1, ease:Power4.easeOut});
      TweenLite.fromTo(query('.newsletter-signup-modal .inner-container')[0], .4, {opacity:0, scaleX:.96, scaleY:.96}, {opacity:1, scaleX:1.0, scaleY:1.0, ease:Power4.easeOut} );

      query('.newsletter-signup-modal .fade-bk')[0].addEventListener('click', closeNewsModal);
      query('.newsletter-signup-modal .close')[0].addEventListener('click', closeNewsModal);

      function closeNewsModal(e) {

        e.preventDefault();

        function rmModal() {id('vp-wrap').removeChild(query('.newsletter-signup-modal')[0]);};
        TweenLite.fromTo(query('.newsletter-signup-modal .fade-bk')[0], .4, {opacity:1}, {opacity:0, ease:Power4.easeOut});
        TweenLite.fromTo(query('.newsletter-signup-modal .inner-container')[0], .4, {opacity:1, scaleX:1.0, scaleY:1.0}, {opacity:0, scaleX:0.96, scaleY:0.96, ease:Power4.easeOut, onComplete:rmModal} );

      };

      //Re init newsletter signup listeners
      newsletterSignup = query('.newsletter-signup')
      _listen();


      return false;
    };





  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     console.log("newsletter.js");
     var dsObject = new window.DS.newsletter();

  });

  //});

})();
