(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      elIndex = base('getElementIndex'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      closestParent = base('closestParent'),
      fillVideo = base('fillVideo'),
      getBackgroundImgURL = base('getBackgroundImgURL'),
      loadImage = base('loadImage'),
      createEvent = base('createEvent');


  window.DS.home = function() {
    var self = this,
        hero = query('.hp-hero'),
        heroCarousel = query('.carousel', hero[0]),
        heroCarouelLis = query('.hp-hero .carousel li'),
        heroCarouselNav = query('.hp-hero-nav', hero[0]),
        heroCarouselLeftRight = query('.hp-hero-nav span', heroCarouselNav[0]),
        heroCarouselLinks = query('.hp-hero-nav li',  heroCarouselNav[0]),
        heroCarouselArrayMax = heroCarouelLis.length - 1,
        carouselAnimationRunning = false,
        discover = query('.hp-discover'),
        discoverCtas = query('.hp-discover-link'),
        carouselAutoscrollInterval;


    init();

    function init() {
      _listen();
      _openAnimation();
      _setupMobileCarouselSwipes();
      _checkCarouselsForVideos();
      _homepageArticleReveal();
      _hasPromo();

      carouselAutoscrollInterval = setInterval(function(){_carouselAutoScroll();}, 6000);
      //_homepageParallax();
    };

    function _listen() {
      eAddAll(heroCarouselLeftRight, 'click', _routeCarouselLeftRightSwitch);
      eAddAll(heroCarouselLinks, 'click', _routeCarouselDirectSwitch);
      hero[0].addEventListener('click', _carouselInteraction);

    };

    function _hasPromo(){
      if(document.getElementsByClassName('hp-promo').length > 0){
        document.body.classList.add('hp-promo-active');
      }
    }

    function _carouselAutoScroll() {
      var navNextClick = createEvent('click'),
          hpHeroNext = query('.hp-hero-nav .next');

      if(hpHeroNext && hpHeroNext[0]){
        hpHeroNext[0].dispatchEvent(navNextClick);
      }

    };

    function _carouselInteraction() {
      //console.log('carousel clear interaction');
      clearInterval(carouselAutoscrollInterval);
      carouselAutoscrollInterval = null;
    };

    function _homepageArticleReveal() {
      var hpController = new ScrollMagic.Controller();
      var headerToolbar = query('.hp-features article.hidden')[0];


      var scene = new ScrollMagic.Scene({
        triggerElement: headerToolbar,
        triggerHook: 0.9

      })
      //.addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
      .on('enter', function(e){

        var hiddenLinks = query('.hp-features article.hidden');
        var linkDelay = 0;

        for(var i=0;i<2;i++) {
          if(hiddenLinks[i]){
            TweenLite.fromTo(hiddenLinks[i], .4, {opacity:0, y:'8%'}, {opacity:1, y:'0%', ease:Power1.easeOut, delay:linkDelay});
            removeClass(hiddenLinks[i], 'hidden');
          }

          linkDelay+=.1;

        }

        scene.destroy();
        hpController.destroy();
        scene = null;
        hpController = null;

        if(query('.hp-features article.hidden')[0]){
          setTimeout(function(){_homepageArticleReveal();}, 100);
        }



      })
      .addTo(hpController);
    };

    function _checkCarouselsForVideos() {

      for(var i=0;i<heroCarouelLis.length;i++) {

        var hero = query('.hp-hero .carousel li[data-slide="'+(i+1)+'"]'),
            bk = query('.hp-hero .carousel li[data-slide="'+(i+1)+'"] .bk-wrap'),
            vid = query('.hp-hero .carousel li[data-slide="'+(i+1)+'"] .carousel-video');



            if(vid[0]){

              vid[0].addEventListener('loadeddata', function(e) {
               fillVideo(e.target, bk[0]);
                window.addEventListener('resize', function(){
                  fillVideo(e.target, bk[0]);
                });

              }, false);



            }



      }
    };





    function _routeCarouselLeftRightSwitch(e) {
      e.preventDefault();


      var _this = this,
          dir = _this.classList.contains('prev') ? 'prev' : 'next',
          activeEl,
          activeIndex,
          newIndex;



      for(var i=0;i<heroCarouelLis.length;i++) {
        if(heroCarouelLis[i].classList.contains('active')) {
          activeEl = heroCarouelLis[i],
          activeIndex = i;
          newIndex = (dir === 'next' ? activeIndex + 1 : activeIndex - 1);
          break;
        }
      }

      //New Index can't be less than 1 or greater than max Index
      newIndex = newIndex<0 ? heroCarouselArrayMax : newIndex;
      newIndex = newIndex>heroCarouselArrayMax ? 0 : newIndex;

      _switchHeroCarousel(newIndex);


      return false;
    };

    function _routeCarouselDirectSwitch(e) {
      e.preventDefault();

      var _this = this,
          newIndex = elIndex(_this);

      _switchHeroCarousel(newIndex);

      return false;
    };


    function _switchHeroCarousel(_index) {

      if(carouselAnimationRunning===true) {return false;}

      carouselAnimationRunning = true;


      var activeCarouselLi = query('li.active', heroCarousel[0]),
          activeCarouselLiBk = query('.bk-wrap', activeCarouselLi[0]),
          activeCarouselLiBkInner = query('.bk', activeCarouselLiBk[0]),
          activeCarouselLiArticle = query('article', activeCarouselLi[0]),
          activeCarouselLiArticleWrap = activeCarouselLiArticle[0].children[0],
          newCarouselLi = heroCarouelLis[_index],
          newCarouselLiBk = query('.bk-wrap', newCarouselLi),
          newCarouselLiBkInner = query('.bk', newCarouselLiBk[0]),
          newCarouselLiArticle = query('article', newCarouselLi[0]),
          newCarouselLiArticleWrap = query('.article-wrap', newCarouselLi),
          activeCarouselIndex,
          dir;


          TweenLite.set(activeCarouselLi[0], {css:{zIndex:3}});
          TweenLite.set(newCarouselLi, {css:{zIndex:4}});

          for(var i=0;i<heroCarouelLis.length;i++) {
            if(heroCarouelLis[i].classList.contains('active')) {
              activeCarouselIndex = i;
              break;
            }
          }

          //Do nothing if same index
          if(activeCarouselIndex===_index) {
            return false;
          }

          dir = 'forward';
          //ONLY FORWARD DIRECTION PER JAE - UNCOMMENT TO CHANGE BACK TO MULTIDIRECTION
          //dir = activeCarouselIndex < _index ? 'forward' : 'back';

          if(window.innerWidth<750) {
            dir = activeCarouselIndex < _index ? 'mobilefw' : 'mobilebk';
            console.log(dir);
          }


          switch(dir) {
            case 'forward':

              TweenLite.fromTo(activeCarouselLiBk[0], .6, {x:'0%'}, {x:'100%', ease:Power1.easeInOut});

              for(var i=0;i<activeCarouselLiBkInner.length;i++) {
                TweenLite.fromTo(activeCarouselLiBkInner[i], .6, {x:'0%'}, {x:'-95%', ease:Power1.easeInOut});
              }

              TweenLite.fromTo(activeCarouselLiArticleWrap, .8, {x:'0%', opacity:1}, {x:'100%', opacity:0, ease:Power1.easeInOut});

              TweenLite.set(newCarouselLi, {x:'0%'});
              TweenLite.fromTo(newCarouselLiBk[0], .6, {x:'-100%'}, {x:'0%', delay:0, ease:Power1.easeInOut});

              for(var j=0;j<newCarouselLiBkInner.length;j++) {
                TweenLite.fromTo(newCarouselLiBkInner[j], .6, {x:'95%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              }

              TweenLite.fromTo(newCarouselLiArticleWrap[0], .8, {x:'-100%', opacity:0}, {x:'0%', opacity:1, ease:Power1.easeInOut, onComplete:updatePosition});
              break;
            case 'back':
              TweenLite.fromTo(activeCarouselLiBk[0], .6, {x:'0%'}, {x:'100%', ease:Power1.easeInOut});
              TweenLite.fromTo(activeCarouselLiBkInner[0], .6, {x:'0%'}, {x:'-95%', ease:Power1.easeInOut});
              TweenLite.fromTo(activeCarouselLiArticleWrap, .8, {x:'0%', opacity:1}, {x:'100%', opacity:0, ease:Power1.easeInOut});

              TweenLite.set(newCarouselLi, {x:'0%'});
              TweenLite.fromTo(newCarouselLiBk[0], .6, {x:'-100%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              TweenLite.fromTo(newCarouselLiBkInner[0], .6, {x:'95%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              TweenLite.fromTo(newCarouselLiArticleWrap[0], .8, {x:'-100%', opacity:0}, {x:'0%', opacity:1, ease:Power1.easeInOut, onComplete:updatePosition});
              break;
            case 'mobilefw':
              TweenLite.fromTo(activeCarouselLiBk[0], .6, {x:'0%'}, {x:'-100%', ease:Power1.easeInOut});
              for(var i=0;i<activeCarouselLiBkInner.length;i++) {
                TweenLite.fromTo(activeCarouselLiBkInner[i], .6, {x:'0%'}, {x:'-95%', ease:Power1.easeInOut});
              }
              TweenLite.fromTo(activeCarouselLiArticleWrap, .6, {x:'0%', opacity:1}, {x:'-100%', opacity:0, ease:Power1.easeInOut});

              TweenLite.set(newCarouselLi, {x:'0%'});
              TweenLite.fromTo(newCarouselLiBk[0], .6, {x:'100%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              for(var j=0;j<newCarouselLiBkInner.length;j++) {
                TweenLite.fromTo(newCarouselLiBkInner[j], .6, {x:'95%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              }
              TweenLite.fromTo(newCarouselLiArticleWrap[0], .6, {x:'100%', opacity:0}, {x:'0%', opacity:1, ease:Power1.easeInOut, onComplete:updatePosition});
              break;
            case 'mobilebk':
              TweenLite.fromTo(activeCarouselLiBk[0], .6, {x:'0%'}, {x:'100%', ease:Power1.easeInOut});
              for(var i=0;i<activeCarouselLiBkInner.length;i++) {
                TweenLite.fromTo(activeCarouselLiBkInner[i], .6, {x:'0%'}, {x:'95%', ease:Power1.easeInOut});
              }
              TweenLite.fromTo(activeCarouselLiArticleWrap, .6, {x:'0%', opacity:1}, {x:'100%', opacity:0, ease:Power1.easeInOut});

              TweenLite.set(newCarouselLi, {x:'0%'});
              TweenLite.fromTo(newCarouselLiBk[0], .6, {x:'-100%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              for(var j=0;j<newCarouselLiBkInner.length;j++) {
                TweenLite.fromTo(newCarouselLiBkInner[j], .6, {x:'-95%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              }
              TweenLite.fromTo(newCarouselLiArticleWrap[0], .6, {x:'-100%', opacity:0}, {x:'0%', opacity:1, ease:Power1.easeInOut, onComplete:updatePosition});
              break;
          }


          function updatePosition() {
            activeCarouselLi[0].removeAttribute('style');
            removeClass(activeCarouselLi, 'active');
            addClass(newCarouselLi, 'active');
            removeClass(heroCarouselLinks, 'active');
            addClass(heroCarouselLinks[_index], 'active');
            carouselAnimationRunning = false;
          };




    };

    function _openAnimation() {
      var header = id('header'),
          whiteBar = query('.white-bar', header),
          toolbar = query('.toolbar', header),
          activeCarouselLi = query('li.active', heroCarousel[0]),
          activeCarouselLiBk = query('.bk-wrap', activeCarouselLi[0]),
          activeCarouselLiBkInner = query('.bk', activeCarouselLiBk[0]),
          activeCarouselLiArticle = query('article', activeCarouselLi[0]),
          activeCarouselLiArticleWrap = activeCarouselLiArticle[0].children[0],
          activeBkImgUrl = getBackgroundImgURL(activeCarouselLiBkInner[0]);

      function runAnimation() {
        carouselAnimationRunning = true;

        TweenLite.fromTo(whiteBar[0], 1.6, {opacity:0, x:'0%'}, {opacity:1, x:'0%', ease:Power1.easeOut});
        TweenLite.fromTo(toolbar[0], .6, {opacity:0}, {opacity:1, delay:.8, ease:Power1.easeOut});

        function updatePosition() {
            activeCarouselLi[0].removeAttribute('style');
            carouselAnimationRunning = false;
        };

        if(window.innerWidth<751) {
          TweenLite.fromTo(activeCarouselLiBk[0], 0, {x:'-100%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
          for(var i=0;i<activeCarouselLiBkInner.length;i++){
            TweenLite.fromTo(activeCarouselLiBkInner[i], 0, {x:'95%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
          }
          TweenLite.fromTo(activeCarouselLiArticleWrap, 0, {x:'-100%', opacity:0}, {x:'0%', opacity:1, delay:0, ease:Power1.easeInOut, onComplete:updatePosition});

        }

        else {
          TweenLite.fromTo(activeCarouselLiBk[0], .7, {x:'-100%'}, {x:'0%', delay:.1, ease:Power1.easeInOut});
          for(var i=0;i<activeCarouselLiBkInner.length;i++){
            TweenLite.fromTo(activeCarouselLiBkInner[i], .7, {x:'95%'}, {x:'0%', delay:.1, ease:Power1.easeInOut});
          }
          TweenLite.fromTo(activeCarouselLiArticleWrap, .8, {x:'-100%', opacity:0}, {x:'0%', opacity:1, delay:.1, ease:Power1.easeInOut, onComplete:updatePosition});
        }
      };

      loadImage(activeBkImgUrl, runAnimation);







    };

    function _setupMobileCarouselSwipes () {
      var _isTouch = ('ontouchstart' in window);
      if(_isTouch){



         var xTouchStart = 0;
         var xTouchDif = 0;
         var xTouchDifMax = 110;
         hero[0].addEventListener('touchstart', function(e) {
             xTouchStart = e.targetTouches[0].pageX;
             xTouchDif = 0;
             //alert();
          }, true);
          hero[0].addEventListener('touchmove', function(e){
             xTouchDif = xTouchStart-e.targetTouches[0].pageX;
          }, true);
          hero[0].addEventListener('touchend', function(e){
              if(Math.abs(xTouchDif) >= xTouchDifMax){

                  if(xTouchDif < 0){
                      //alert('back');
                      //Go Back.
                      if (heroCarouselLeftRight[0].onclick) {
                         heroCarouselLeftRight[0].onclick();
                      } else if (heroCarouselLeftRight[0].click) {
                         heroCarouselLeftRight[0].click();
                      }



                  } else {
                      //alert('forward');
                      //Go Forward.
                      if (heroCarouselLeftRight[1].onclick) {
                         heroCarouselLeftRight[1].onclick();
                      } else if (heroCarouselLeftRight[1].click) {
                         heroCarouselLeftRight[1].click();
                      }



                  }
              }
          }, true);


      }/* End Touch Detection */
    };


    function _homepageParallax() {

      // console.log('init hp parallax');

        var hpScrollController = new ScrollMagic.Controller();

        // define movement of panels
        var wipeAnimation = new TimelineMax()
        .to(".hp-hero .carousel", 20, {y: "20%", ease: Linear.easeNone});  // in from left

        console.log(wipeAnimation);

       // create scene to pin and link animation
        var heroScene = new ScrollMagic.Scene({
        triggerElement: ".hp-features",
        //triggerHook: "onLeave",
         triggerHook:0.9,
        //duration: "300%"
        })
        .setTween(wipeAnimation)
        .addIndicators() // add indicators (requires plugin)
        .addTo(hpScrollController);



    };






  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     var trigger = query('.hp-hero');

      if(trigger.length) {
       console.log("home.js");
       var dsObject = new window.DS.home();
      };

  });

  //});

})();
