(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      elIndex = base('getElementIndex'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass');


  window.DS.plp = function() {
    var self = this;



    init();

    function init() {
      _listen();
      _plpHiddenReveal();
    }

    function _listen() {


    }

    function _plpHiddenReveal() {
        var controller = new ScrollMagic.Controller();
        var headerToolbar = query('.product-grid li.hidden')[0];

        var scene = new ScrollMagic.Scene({
          triggerElement: headerToolbar,
          triggerHook: 0.9

        })
        //.addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
        .on('enter', function(e){

          var hiddenLinks = query('.product-grid li.hidden');
          var linkDelay = 0;

          for(var i=0;i<10;i++) {
            if(hiddenLinks[i]){
              TweenLite.fromTo(hiddenLinks[i], .4, {opacity:0, y:'8%'}, {opacity:1, y:'0%', ease:Power1.easeOut, delay:linkDelay});
              removeClass(hiddenLinks[i], 'hidden');
            }

            linkDelay+=.1;

          }

          scene.destroy();
          controller.destroy();
          scene = null;
          controller = null;

          if(query('.product-grid li.hidden')[0]){
            setTimeout(function(){_plpHiddenReveal();}, 100);
          }



        })
        .addTo(controller);

    }





  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     var trigger = query('.catalog-category-view');

     if(trigger.length) {
      console.log("plp.js");
      var dsObject = new window.DS.plp();
     }


  });

  //});

})();
