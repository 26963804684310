(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      elIndex = base('getElementIndex'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      closestParent = base('closestParent'),
      childWithClass = base('childWithClass');


  window.DS.checkout = function() {
    var self = this,
        ckAuthBTN = query('.ck-auth-continue-btn'),
        agreementsLabel = query('.checkout-agreement'),
        numberInputs = query('input[type=number]'),
        checkoutButton,
        selectedFirstShipping = false;


    init();

    function init() {
      _listen();
      //_checkPositionSummary();
    }

    function _listen() {
      //Because content is loaded via ajax & listen is reinitalized afrer each load, must check for el before applying listener
      if(ckAuthBTN[0]) {
        ckAuthBTN[0].removeEventListener('click', _authRoute);
        ckAuthBTN[0].addEventListener('click', _authRoute);
      }

      window.addEventListener('hashchange', _scrollToHash);

      setInterval(function(){
        agreementsLabel = query('.checkout-agreement');
        numberInputs = query('input[type=number]');
        checkoutButton = query('#checkout button');


        eRmAll(agreementsLabel, 'click', _agreementLabelChange);
        eAddAll(agreementsLabel, 'click', _agreementLabelChange);

        eRmAll(numberInputs, 'mousewheel', _preventScrollOnNumberInputs);
        eAddAll(numberInputs, 'mousewheel', _preventScrollOnNumberInputs);

        eRmAll(checkoutButton, 'click', _checkoutErrorScroll);
        eAddAll(checkoutButton, 'click', _checkoutErrorScroll);

        // if(selectedFirstShipping===false && query('.table-checkout-shipping-method tbody .row input')[0]) {
        //   selectedFirstShipping = true;
        //   var shippingRadios = query('.table-checkout-shipping-method tbody .row input');

        //   for(var i=0;i<shippingRadios.length;i++) {
        //     shippingRadios[i].checked = false;
        //   }

        //   query('.table-checkout-shipping-method tbody .row input')[0].checked = true;
        // }

      }, 1000);

    }

    function _checkoutErrorScroll() {
      //console.log('checkout error scrol');
      var errors = query('#checkout ._error');
      TweenLite.to(window, .6, {scrollTo:errors[0], ease:Power4.easeInOut});
    };

    function _scrollToHash() {
      var hash = window.location.hash;
      TweenLite.to(window, .6, {scrollTo:hash, ease:Power4.easeInOut});
    };

    function _preventScrollOnNumberInputs(e) {
      e.preventDefault();
    }

    function _agreementLabelChange(e) {
      var _this = this,
          ckInput = this.children[0],
          isChecked = ckInput.checked;

      function switchChecks(checkVal) {
        for(var i=0;i<agreementsLabel.length;i++) {
          agreementsLabel[i].children[0].checked = checkVal;
        }
      };

      switch(isChecked) {
        case true:
          switchChecks(false);
          break;
        case false:
          switchChecks(true);
          break;
      }

    }

    function _authRoute(e) {
      e.preventDefault();

      var _this = this,
          radios = query('input[name="fek_ck_opts_login"]');

      function routeURLChecked(id) {
        console.log(id);
        switch(id) {
          case 'fek_ck_opts_guest':
            var authWrapper = query('.authentication-wrapper');
            authWrapper[0].style.display = 'none';
            break;
          case 'fek_ck_opts_register':
            window.location.pathname = '/customer/account/create/';
            break;
        }
      };

      for(var i=0;i<radios.length;i++) {
        if(radios[i].checked){
          routeURLChecked(radios[i].id);
        }
      }



      return false;
    };


    function _checkPositionSummary() {
      var controller = new ScrollMagic.Controller();

      var scene = new ScrollMagic.Scene({
        triggerElement: "#checkoutSteps",
        triggerHook: 0.0
      })
      .addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
      .on('enter', function(e){
        console.log('scroll enter');
        addClass(id('opc-sidebar'), 'stuck');
        addClass(query('#header .toolbar')[0], 'hidden');
      })
      .on('leave', function(){
        console.log('scroll leave');
        removeClass(id('opc-sidebar'), 'stuck');
        removeClass(query('#header .toolbar')[0], 'hidden');
      })
      .addTo(controller);
    }


  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     var trigger = query('.checkout-index-index');

     if(trigger.length) {
      //console.log("checkout.js - DOM loaded and parsed");
      //var dsObject = new window.DS.checkout();
     }


  });

  //});

})();
