(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      elIndex = base('getElementIndex'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      closestParent = base('closestParent'),
      childWithClass = base('childWithClass'),
      createEvent = base('createEvent');


  window.DS.search = function() {
    var self = this,
        salonResults = query('.search.results .content-item'),
        salonTab = document.createElement('li'),
        salonLink = document.createElement('a'),
        salonSearchContainer = query('.salon-search-container ul'),
        searchContainer = query('.search__result-tabs')[0],
        salonResultsContainer = query('.salon-search-container'),
        searchResultsContainer = query('.search__result-index'),
        productResultsContainer = query('.search.results'),
        messageNotice = query('.message.notice');


    init();

    function init(){

      _moveFilter();

      if(salonResults.length > 0) {
        _addTab();
        _cloneSalon();
        _listen();
        if (window.location.search.indexOf("?index=") == -1) {
          _salonTabClick();
        }
      }
    }

    function _listen() {
      // salonLink.addEventListener('click', _salonTabClick);
      eAddAll(query('.search__result-tabs'), 'click', _handleTabClick);
    }

    function _addTab(){
      var salonCount = salonResults.length;
      salonLink.innerHTML = 'Salon (' + salonCount + ')';
      salonLink.setAttribute('href', '#');
      salonTab.append(salonLink);
      salonTab.classList.add('salon-tab');
      searchContainer.append(salonTab);
    }

    function _cloneSalon() {
      salonResults.forEach(function(result){
        var resultElement = result;
        var resultClone = resultElement.cloneNode();
        var resultLI = document.createElement('li');
        var resultDIV = document.createElement('div');

        resultElement.classList.remove('ct-link')
        resultDIV.append(resultElement);
        resultDIV.classList.add('title');
        resultLI.append(resultDIV);

        salonSearchContainer[0].append(resultLI);
      })
    }

    function _salonTabClick(e){
      if (e) {
        e.preventDefault();
      }
      var tabLinks = query('.search__result-tabs li a');
      // var salonResultsContainer = query('.salon-search-container');
      // var searchResultsContainer = query('.search__result-index');
      removeClass(tabLinks, 'active');
      addClass(salonLink, 'active');
      if(searchResultsContainer.length > 0){
        addClass(searchResultsContainer, 'inactive');
      }
      if(messageNotice.length > 0) {
        addClass(messageNotice, 'inactive');
      }

      if(productResultsContainer.length > 0) {
        addClass(productResultsContainer, 'inactive');
      }
      addClass(salonResultsContainer, 'active');

    }

    function _handleTabClick(e){
      var tabLinks = query('.search__result-tabs li a');

      if(e.target.parentElement.classList.contains('salon-tab')) {
        _salonTabClick(e);
      } else {
        removeClass(tabLinks, 'active');
        removeClass(salonResultsContainer, 'active');
        addClass(e.target, 'active');
        if(searchResultsContainer.length > 0){
          removeClass(searchResultsContainer, 'inactive');
        }
        if(messageNotice.length > 0) {
          removeClass(messageNotice, 'inactive');
        }
        if(productResultsContainer.length > 0) {
          removeClass(productResultsContainer, 'inactive');
        }
      }
    }

    function _moveFilter(){
      var filterOptions = query('.block.filter');
      var searchResultsTab = query('.search__result-tabs li');
      if(filterOptions.length > 0 && searchResultsTab[0].children[0].innerHTML.trim() === 'Products'){
        searchResultsTab[0].append(filterOptions[0]);
      }
    }

  };


  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     var trigger = query('.catalogsearch-result-index');

     if(trigger.length) {
      console.log("search.js");
      var dsObject = new window.DS.search();
     }


  });

  //});

})();
