(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
    query = base('query'),
    id = base('id'),
    elIndex = base('getElementIndex'),
    eAddAll = base('eAddAll'),
    eRmAll = base('eRmAll'),
    addClass = base('addClass'),
    removeClass = base('removeClass'),
    toggleClass = base('toggleClass'),
    closestParent = base('closestParent'),
    childWithClass = base('childWithClass'),
    ajax = base('ajax'),
    fillVideo = base('fillVideo'),
    urlPopup = base('urlPopup'),
    createEvent = base('createEvent');

  window.DS.pdp = function() {
    var self = this,
      pdpHero = query('.pdp-hero'),
      pdpHeroImg = query('.product-image', pdpHero[0]),
      pdpHeroFinalPrice = query('.final-price', pdpHero[0]),
      pdpHeroOriginalPrice = id('fekkai-discount'),
      pdpHeroProductSize = query('.product-size', pdpHero[0]),
      cartButton = query('.cart-add.pdp-qty', pdpHero[0]),
      inputSelector = query('.prod_qty', pdpHero[0]),
      pdpDetails = query('.pdp-details'),
      pdpDetailsNav = query('nav', pdpDetails[0]),
      pdpDetailsLinks = query('a', pdpDetailsNav[0]),
      pdpDetailsContent = query('ul', pdpDetails[0]),
      pdpDetailsLIs = query('li', pdpDetailsContent[0]),
      prodSizeSelect = id('prod_size'),
      formKeyValue = document.querySelector('input[name="form_key"]'),
      addProdToFav = query('.product-description .cta-fav'),
      pdpShareLinks = query('.pdp-hero .social-icons a'),
      readMoreLink = query('.product-read-more');


    init();

    function init() {
      _listen();
      _checkForVideoResize();
    }

    function _listen() {
      eAddAll(pdpDetailsLinks, 'click', _pdpDiscoverChange)
      prodSizeSelect.addEventListener('change', _configurableChange);

      if (prodSizeSelect) {
        var firstSaleableOption = query('#prod_size option[data-saleable="true"]');
        if (firstSaleableOption && firstSaleableOption[0]) {
          prodSizeSelect.value = firstSaleableOption[0].value;
          prodSizeSelect.dispatchEvent(createEvent('change'));
        }
      }

      if (addProdToFav[0]) {
        addProdToFav[0].addEventListener('click', _addProdToFav);
      }

      eAddAll(pdpShareLinks, 'click', _pdpSharePage);

      readMoreLink[0].addEventListener('click', _scrollDownToReadMore);
    }

    function _addProdToFav(e) {
      e.preventDefault();

      var _this = this,
        selectedId = id('prod_size').value;

      if (_this.classList.contains('updated')) {
        window.location.pathname = '/wishlist/';
        return false;
      }

      function _addedToFavorites(json) {
        _this.textContent = 'Added to Wishlist';
        addClass(_this, 'updated');
      };

      ajax('POST', '/fekkaicustomer/Ajax/AddWishlistItem', {
        'form_key': formKeyValue.value,
        'id': selectedId
      }, _addedToFavorites);


      return false;
    };

    function _configurableChange(e) {
      e.preventDefault();

      var _this = this,
        value = this.value,
        selectedOptionEl = this.options[this.selectedIndex],
        isSaleable = e.target.options[e.target.selectedIndex].dataset.saleable,
        minVal = parseFloat(e.target.options[e.target.selectedIndex].dataset.min),
        maxVal = parseFloat(e.target.options[e.target.selectedIndex].dataset.max),
        qtyVal = parseFloat(e.target.options[e.target.selectedIndex].dataset.qty),
        selectedOptionData = JSON.parse(selectedOptionEl.getAttribute('data-product')),
        addBtn = query('.product-form .cart-add')[0],
        soldOutP = query('.product-form .sold-out')[0],
        prodQtyInput = id('prod_qty');

      if (qtyVal < maxVal) {
        maxVal = qtyVal;
      }

      if (minVal < 1) {
        minVal = 1;
      }

      switch (isSaleable) {
        case 'false':
          addClass(addBtn, 'hide');
          soldOutP.textContent = 'Sold Out';
          break;
        case 'true':
          removeClass(addBtn, 'hide');
          soldOutP.textContent = '';
          break;
      }

      pdpHeroImg[0].src = selectedOptionData.img_hero;
      pdpHeroFinalPrice[0].textContent = selectedOptionData.price;
      document.querySelector('#fekkai-discount').textContent = '$' + parseFloat(selectedOptionData.og_price).toFixed(2);
      pdpHeroProductSize[0].children[0].textContent = selectedOptionData.display_weight;

      cartButton[0].setAttribute('data-id', selectedOptionData.id);

      inputSelector[0].setAttribute('min', selectedOptionData.stockInfo.min_sale_qty);
      inputSelector[0].setAttribute('max', selectedOptionData.stockInfo.qty);

      prodQtyInput.setAttribute('min', selectedOptionData.stockInfo.min_sale_qty);
      prodQtyInput.setAttribute('max', maxVal);

      if (selectedOptionData.stockInfo.use_config_qty_increments === 0 && selectedOptionData.stockInfo.enable_qty_increments === 1) {

        inputSelector[0].setAttribute('step', selectedOptionData.stockInfo.qty_increments);
      } else {
        inputSelector[0].setAttribute('step', '');
      }

      inputSelector[0].value = '1';

      return false;
    };


    function _pdpDiscoverChange(e) {
      e.preventDefault();

      var _this = this,
        _index = elIndex(_this),
        curLiActive = query('li.active', pdpDetailsContent[0]);

      removeClass(pdpDetailsLinks, 'active');
      addClass(_this, 'active');

      function changeActiveLi() {
        removeClass(curLiActive, 'active');
      };

      addClass(pdpDetailsLIs[_index], 'active');

      TweenLite.fromTo(curLiActive, .3, {
        opacity: 1
      }, {
        opacity: 0,
        ease: Power1.easeOut
      });
      TweenLite.fromTo(pdpDetailsLIs[_index], .3, {
        opacity: 0
      }, {
        opacity: 1,
        delay: .2,
        onComplete: changeActiveLi,
        ease: Power1.easeOut
      })

      return false;
    };

    function _checkForVideoResize() {
      var prodInfo = query('.pdp-hero .hero-wrapper'),
        vid = query('.pdp-hero .hero-wrapper .pdp-video');

      if (vid[0]) {

        vid[0].addEventListener('loadeddata', function(e) {
          fillVideo(e.target, prodInfo[0]);
          window.addEventListener('resize', function() {
            fillVideo(e.target, prodInfo[0]);
          });

        }, false);
      }
    };

    function _pdpSharePage(e) {
      e.preventDefault();

      var _this = this,
        socialType = _this.getAttribute('class');

      switch (socialType) {
        case 'facebook':
          var url = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href;
          urlPopup(url, 'Share Fekkai on Facebook!', 600, 490);
          break;
        case 'twitter':
          var url = 'https://twitter.com/home?status=',
            productTitle = query('.product-description h1');
          url = url + encodeURIComponent(' ') + encodeURIComponent(productTitle[0].textContent) + encodeURIComponent(' ') + encodeURIComponent(window.location.href);
          urlPopup(url, 'Share Fekkai on Twitter!', 600, 490);
          break;
      }

      return false;
    }

    function _scrollDownToReadMore(e) {
      e.preventDefault();

      TweenLite.to(window, .6, {
        scrollTo: '.pdp-details',
        ease: Power4.easeInOut
      });

      return false;
    };
  };

  document.addEventListener("DOMContentLoaded", function(event) {
    var trigger = query('.pdp-details');

    if (trigger.length) {
      var dsObject = new window.DS.pdp();
    }
  });
})();
