(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      elIndex = base('getElementIndex'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      closestParent = base('closestParent'),
      childWithClass = base('childWithClass'),
      createEvent = base('createEvent');


  window.DS.salonDetail = function() {
    var self = this,
        stylistList = query('.salon-detail-page .stylists'),
        stylists = query('.salon-detail-page .stylists .stylist'),
        prevArrow = query('.salon-detail-page .prev.arrow'),
        nextArrow = query('.salon-detail-page .next.arrow'),
        map = id('map'),
        stylistIndex = 0,
        stylistCount = stylists.length,
        bioButtons = query('.salon-content .view-bio'),
        hero = query('.salon-hero'),
        heroCarousel = query('.salon-hero .salon-carousel'),
        heroCarouelLis = query('.salon-hero .salon-carousel li'),
        heroCarouselNav = query('.salon-hero .hp-hero-nav'),
        heroCarouselLeftRight = query('.salon-hero .hp-hero-nav span'),
        heroCarouselLinks = query('.salon-hero .hp-hero-nav li'),
        heroCarouselArrayMax = heroCarouelLis.length - 1,
        carouselAnimationRunning = false,
        carouselAutoscrollInterval;


        // console.log(stylists);
        // console.log(stylistCount);


    init();

    function init() {
      _listen();
      _pinInfo();
      _openAnimation();
      _setupMobileCarouselSwipes();
      if(map!==null) {
        _initMap();
      }

      carouselAutoscrollInterval = setInterval(function(){_carouselAutoScroll();}, 6000);

    }

    function _listen() {
      eAddAll(prevArrow, 'click', _salonDetailPrevStylist);
      eAddAll(nextArrow, 'click', _salonDetailNextStylist);
      window.addEventListener('resize',_resize);
      _detectSwipes();


      eAddAll(bioButtons, 'click', _openBio);

      eAddAll(heroCarouselLeftRight, 'click', _routeCarouselLeftRightSwitch);
      eAddAll(heroCarouselLinks, 'click', _routeCarouselDirectSwitch);

      hero[0].addEventListener('click', _carouselInteraction);

    }

    function _carouselAutoScroll() {
      var navNextClick = createEvent('click');
      query('.hp-hero-nav .next')[0].dispatchEvent(navNextClick);
    };

    function _carouselInteraction() {
      //console.log('carousel clear interaction');
      clearInterval(carouselAutoscrollInterval);
      carouselAutoscrollInterval = null;
    };

    function _detectSwipes() {
      var _isTouch = ('ontouchstart' in window);
      if(_isTouch){
        var xTouchStart = 0;
        var xTouchDif = 0;
        var xTouchDifMax = 110;
        window.addEventListener('touchstart', function(e) {
           xTouchStart = e.targetTouches[0].pageX;
           xTouchDif = 0;
        }, true);
        window.addEventListener('touchmove', function(e){
           xTouchDif = xTouchStart-e.targetTouches[0].pageX;
        }, true);
        window.addEventListener('touchend', function(e){
          if(Math.abs(xTouchDif) >= xTouchDifMax){
            if(xTouchDif < 0) {
              _salonDetailPrevStylist();
            } else {
              _salonDetailNextStylist();
            }
          }
        }, true);
      }
    }

    function _resize() {
      _animateStylistSlides();
    }

    function _salonDetailPrevStylist() {
      if(stylistIndex === 0) {
        stylistIndex = stylistCount - 1;
      } else {
        stylistIndex--;
      }
      _animateStylistSlides();
    }

    function _salonDetailNextStylist() {
      if(stylistIndex === stylistCount - 1) {
        stylistIndex = 0;
      } else {
        stylistIndex++;
      }
      _animateStylistSlides();
    }

    function _pinInfo() {
        var controller = new ScrollMagic.Controller();
        var headerToolbar = query('#header .toolbar')[0];

        var scene = new ScrollMagic.Scene({
          triggerElement: ".salon-detail-page",
          triggerHook: 0.0

        })
        .offset(327)
        //.addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
        .on('enter', function(e){
          removeClass(query('.salon-sidebar')[0], 'stuck-bottom');
          addClass(query('.salon-sidebar')[0], 'stuck-top');
          addClass(headerToolbar, 'hide');
        })
        .on('leave', function(){
          removeClass(query('.salon-sidebar')[0], 'stuck-top');
          removeClass(query('.salon-sidebar')[0], 'stuck-bottom');
          removeClass(headerToolbar, 'hide');
        })
        .addTo(controller);

        var scene2 = new ScrollMagic.Scene({
          triggerElement: "#footer",
          triggerHook: 1.0
        })
        //.addIndicators({name: "1 (duration: 0)"}) // add indicators (requires plugin)
        .on('enter', function(e){
          removeClass(query('.salon-sidebar')[0], 'stuck-top');
          addClass(query('.salon-sidebar')[0], 'stuck-bottom');
        })
        .on('leave', function(){
          removeClass(query('.salon-sidebar')[0], 'stuck-bottom');
          addClass(query('.salon-sidebar')[0], 'stuck-top');
        })
        .addTo(controller);
    }

    function _animateStylistSlides() {
      //console.log(stylistIndex);
      var currDot = query('.salon-detail-page .dots .dot.current');
      var newCurrDot = query('.salon-detail-page .dots .dot:nth-child(' + (stylistIndex + 1) + ')');
      removeClass(currDot, 'current');
      addClass(newCurrDot, 'current');
      if(window.innerWidth > 750) {
        stylists.forEach(function(el) {
          el.setAttribute('style','transform: translateX(-' + 322*stylistIndex + 'px)');
        });
      } else {
        stylists.forEach(function(el) {
          el.setAttribute('style','transform: translateX(-' + 292*stylistIndex + 'px)');
        });
      }
    }



    function _initMap() {
        var apiKey = 'AIzaSyANkxXe2bjlravhjE_4Y3lCcShEX8Yjo_0',
            tag = document.createElement('script'),
            firstScriptTag = document.getElementsByTagName('script')[0];

            tag.src = "https://maps.googleapis.com/maps/api/js?key="+apiKey+"&callback=fekkaisalondetailmap";
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);



     };

     function _openBio(e) {
      e.preventDefault();

      var _this = this,
          refLink = _this.getAttribute('data-reslink'),
          desc = _this.getAttribute('data-description'),
          title = _this.previousElementSibling,
          name = title.previousElementSibling,
          img = _this.getAttribute('data-img'),
          popup = query('.salon-detail-popup'),
          popupBK = query('.salon-detail-popup .bk'),
          closePopup = query('.salon-detail-popup .close'),
          modalPic = query('.salon-detail-popup .left'),
          modalTitle = query('.salon-detail-popup .title'),
          modalName = query('.salon-detail-popup .name'),
          modalContent = query('.salon-detail-popup .content-scroller'),
          consultLink = query('.salon-detail-popup .consult-link');

      title = title.textContent;
      name = name.textContent;


      addClass(popup[0], 'open');
      addClass(document.body, 'noscroll');


      modalPic[0].style.backgroundImage = "url('"+img+"')";
      modalTitle[0].textContent = title;
      modalName[0].textContent = name;
      modalContent[0].innerHTML = desc;
      consultLink[0].setAttribute('href', refLink);

      if(refLink===undefined || refLink==='')  {
        addClass(consultLink[0], 'hide');
      }

      else {
        removeClass(consultLink[0], 'hide');
      }



      TweenLite.fromTo(popup[0], .4, {opacity:0}, {opacity:1});


      function _closeBio(e) {
        e.preventDefault();

        function hideBio() {
          removeClass(popup[0], 'open');
          removeClass(document.body, 'noscroll');
        };

        TweenLite.fromTo(popup[0], .4, {opacity:1}, {opacity:0, onComplete:hideBio});


        return false;
      };

      closePopup[0].removeEventListener('click', _closeBio);
      closePopup[0].addEventListener('click', _closeBio);
      popupBK[0].removeEventListener('click', _closeBio);
      popupBK[0].addEventListener('click', _closeBio);





      return false;
    };

    function _openAnimation() {
      var activeCarouselLi = query('.salon-hero li.active'),
          activeCarouselLiBk = query('.salon-hero li.active .bk-wrap'),
          activeCarouselLiBkInner = query('.salon-hero li.active .bk', activeCarouselLiBk[0]);

      carouselAnimationRunning = true;

      function updatePosition() {
          activeCarouselLi[0].removeAttribute('style');
          carouselAnimationRunning = false;
        };

      TweenLite.fromTo(activeCarouselLiBk[0], .76, {x:'-100%'}, {x:'0%', delay:.1, ease:Power1.easeInOut});
      TweenLite.fromTo(activeCarouselLiBkInner[0], .76, {x:'95%'}, {x:'0%', delay:.1, ease:Power1.easeInOut, onComplete:updatePosition});


    };

    function _switchHeroCarousel(_index) {



      if(carouselAnimationRunning===true) {return false;}

      carouselAnimationRunning = true;

      var activeCarouselLi = query('.salon-hero li.active'),
          activeCarouselLiBk = query('.salon-hero li.active .bk-wrap'),
          activeCarouselLiBkInner = query('.salon-hero li.active .bk'),
          activeCarouselLiArticle = query('.salon-hero li.active article'),
          newCarouselLi = heroCarouelLis[_index],
          newCarouselLiBk = query('.bk-wrap', newCarouselLi),
          newCarouselLiBkInner = query('.bk', newCarouselLiBk[0]),
          activeCarouselIndex,
          dir;

          // console.log(activeCarouselLi);
          // console.log(newCarouselLi);


          TweenLite.set(activeCarouselLi[0], {css:{zIndex:3}});
          TweenLite.set(newCarouselLi, {css:{zIndex:4}});

          for(var i=0;i<heroCarouelLis.length;i++) {
            if(heroCarouelLis[i].classList.contains('active')) {
              activeCarouselIndex = i;
              break;
            }
          }

          //Do nothing if same index
          if(activeCarouselIndex===_index) {
            return false;
          }

          dir = 'forward';

          if(window.innerWidth<750) {
            dir = activeCarouselIndex < _index ? 'mobilefw' : 'mobilebk';
            console.log(dir);
          }
          //ONLY FORWARD DIRECTION PER JAE - UNCOMMENT TO CHANGE BACK TO MULTIDIRECTION
          //dir = activeCarouselIndex < _index ? 'forward' : 'back';


          switch(dir) {
            case 'forward':
              TweenLite.fromTo(activeCarouselLiBk[0], .6, {x:'0%'}, {x:'100%', ease:Power1.easeInOut});
              TweenLite.fromTo(activeCarouselLiBkInner[0], .6, {x:'0%'}, {x:'-95%', ease:Power1.easeInOut});

              TweenLite.set(newCarouselLi, {x:'0%'});
              TweenLite.fromTo(newCarouselLiBk[0], .6, {x:'-100%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              TweenLite.fromTo(newCarouselLiBkInner[0], .6, {x:'95%'}, {x:'0%', delay:0, ease:Power1.easeInOut, onComplete:updatePosition});
              break;
            case 'back':
              TweenLite.fromTo(activeCarouselLiBk[0], .6, {x:'0%'}, {x:'100%', ease:Power1.easeInOut});
              TweenLite.fromTo(activeCarouselLiBkInner[0], .6, {x:'0%'}, {x:'-95%', ease:Power1.easeInOut});

              TweenLite.set(newCarouselLi, {x:'0%'});
              TweenLite.fromTo(newCarouselLiBk[0], .6, {x:'-100%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              TweenLite.fromTo(newCarouselLiBkInner[0], .6, {x:'95%'}, {x:'0%', delay:0, ease:Power1.easeInOut, onComplete:updatePosition});
              break;
             case 'mobilefw':
              TweenLite.fromTo(activeCarouselLiBk[0], .6, {x:'0%'}, {x:'-100%', ease:Power1.easeInOut, onComplete:updatePosition});
              for(var i=0;i<activeCarouselLiBkInner.length;i++) {
                TweenLite.fromTo(activeCarouselLiBkInner[i], .6, {x:'0%'}, {x:'-95%', ease:Power1.easeInOut});
              }
              //TweenLite.fromTo(activeCarouselLiArticleWrap, .6, {x:'0%', opacity:1}, {x:'-100%', opacity:0, ease:Power1.easeInOut});

              TweenLite.set(newCarouselLi, {x:'0%'});
              TweenLite.fromTo(newCarouselLiBk[0], .6, {x:'100%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              for(var j=0;j<newCarouselLiBkInner.length;j++) {
                TweenLite.fromTo(newCarouselLiBkInner[j], .6, {x:'95%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              }
              //TweenLite.fromTo(newCarouselLiArticleWrap[0], .6, {x:'100%', opacity:0}, {x:'0%', opacity:1, ease:Power1.easeInOut, onComplete:updatePosition});
              break;
            case 'mobilebk':
              TweenLite.fromTo(activeCarouselLiBk[0], .6, {x:'0%'}, {x:'100%', ease:Power1.easeInOut});
              for(var i=0;i<activeCarouselLiBkInner.length;i++) {
                TweenLite.fromTo(activeCarouselLiBkInner[i], .6, {x:'0%'}, {x:'95%', ease:Power1.easeInOut});
              }
              //TweenLite.fromTo(activeCarouselLiArticleWrap, .6, {x:'0%', opacity:1}, {x:'100%', opacity:0, ease:Power1.easeInOut});

              TweenLite.set(newCarouselLi, {x:'0%'});
              TweenLite.fromTo(newCarouselLiBk[0], .6, {x:'-100%'}, {x:'0%', delay:0, ease:Power1.easeInOut, onComplete:updatePosition});
              for(var j=0;j<newCarouselLiBkInner.length;j++) {
                TweenLite.fromTo(newCarouselLiBkInner[j], .6, {x:'-95%'}, {x:'0%', delay:0, ease:Power1.easeInOut});
              }
              //TweenLite.fromTo(newCarouselLiArticleWrap[0], .6, {x:'-100%', opacity:0}, {x:'0%', opacity:1, ease:Power1.easeInOut, onComplete:updatePosition});
              break;
          }


          function updatePosition() {
            activeCarouselLi[0].removeAttribute('style');
            removeClass(activeCarouselLi, 'active');
            addClass(newCarouselLi, 'active');
            removeClass(heroCarouselLinks, 'active');
            addClass(heroCarouselLinks[_index], 'active');
            carouselAnimationRunning = false;
          };




    };

    function _routeCarouselLeftRightSwitch(e) {
      e.preventDefault();


      var _this = this,
          dir = _this.classList.contains('prev') ? 'prev' : 'next',
          activeEl,
          activeIndex,
          newIndex;



      for(var i=0;i<heroCarouelLis.length;i++) {
        if(heroCarouelLis[i].classList.contains('active')) {
          activeEl = heroCarouelLis[i],
          activeIndex = i;
          newIndex = (dir === 'next' ? activeIndex + 1 : activeIndex - 1);
          break;
        }
      }

      //New Index can't be less than 1 or greater than max Index
      newIndex = newIndex<0 ? heroCarouselArrayMax : newIndex;
      newIndex = newIndex>heroCarouselArrayMax ? 0 : newIndex;

      //console.log(newIndex);
      _switchHeroCarousel(newIndex);


      return false;
    };

    function _routeCarouselDirectSwitch(e) {
      e.preventDefault();

      var _this = this,
          newIndex = elIndex(_this);

      //console.log(newIndex);
      _switchHeroCarousel(newIndex);

      return false;
    };

    function _setupMobileCarouselSwipes () {
      var _isTouch = ('ontouchstart' in window);
      if(_isTouch){



         var xTouchStart = 0;
         var xTouchDif = 0;
         var xTouchDifMax = 110;
         hero[0].addEventListener('touchstart', function(e) {
             xTouchStart = e.targetTouches[0].pageX;
             xTouchDif = 0;
          }, true);
          hero[0].addEventListener('touchmove', function(e){
             xTouchDif = xTouchStart-e.targetTouches[0].pageX;
          }, true);
          hero[0].addEventListener('touchend', function(e){
              if(Math.abs(xTouchDif) >= xTouchDifMax){

                  if(xTouchDif < 0){
                      //alert('back');
                      //Go Back.
                      if (heroCarouselLeftRight[0].onclick) {
                         heroCarouselLeftRight[0].onclick();
                      } else if (heroCarouselLeftRight[0].click) {
                         heroCarouselLeftRight[0].click();
                      }



                  } else {
                      //alert('forward');
                      //Go Forward.
                      if (heroCarouselLeftRight[1].onclick) {
                         heroCarouselLeftRight[1].onclick();
                      } else if (heroCarouselLeftRight[1].click) {
                         heroCarouselLeftRight[1].click();
                      }



                  }
              }
          }, true);


      }/* End Touch Detection */
    };



  };









  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     var trigger = query('.salon-detail-page');

     if(trigger.length) {
      console.log("salon-detail.js");
      var dsObject = new window.DS.salonDetail();
     }


  });

  //});

})();





