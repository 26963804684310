(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      elIndex = base('getElementIndex'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      closestParent = base('closestParent'),
      childWithClass = base('childWithClass');


  window.DS.cart = function() {
    var self = this,
        fekCouponInput = query('.fek-coupon-input'),
        checkoutButton = query('.fek-cart-proceed'),
        clearCoupon = query('.fek-clear-coupon-field'),
        couponSubmit = query('.fek-coupon-submit'),
        couponPaused = false;


    init();

    function init() {
      _listen();
      checkApplied();
    }

    function _listen() {
      eRmAll(fekCouponInput, 'change', _couponInputChange);
      eAddAll(fekCouponInput, 'change', _couponInputChange);

      eRmAll(fekCouponInput, 'keydown', _showActive);
      eAddAll(fekCouponInput, 'keydown', _showActive);

      eRmAll(checkoutButton, 'click', _hasAppliedCoupon);
      eAddAll(checkoutButton, 'click', _hasAppliedCoupon);

      eRmAll(clearCoupon, 'click', _clearCoupon);
      eAddAll(clearCoupon, 'click', _clearCoupon);
    }

    function _showActive() {
      var hasValue = this.value === '' || this.value === undefined ? false : true;
      hasValue ? addClass(couponSubmit[0], 'active') : removeClass(couponSubmit[0], 'active');
    }

    function _couponInputChange(e) {
      var hasValue = this.value === '' || this.value === undefined ? false : true;
      hasValue ? addClass(this.parentElement, 'delete-notice') : removeClass(this.parentElement, 'delete-notice');
      hasValue ? addClass(couponSubmit[0], 'active') : removeClass(couponSubmit[0], 'active');

    }

    function _clearCoupon(e) {
      fekCouponInput[0].value = '';
      removeClass(fekCouponInput[0].parentElement, 'delete-notice');
      couponSubmit[0].dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      }));
    }

    function _hasAppliedCoupon(e) {
      if(couponPaused===false && (fekCouponInput[0].value !== '' && fekCouponInput[0].value !== undefined )&& query('.fek-coupon-code-applied').length===0) {
        var couponWarning = query('.fek-coupon-warning')[0];
        couponWarning.textContent = couponWarning.getAttribute('data-warning');
        e.preventDefault();
        eRmAll(checkoutButton, 'click', _hasAppliedCoupon);
        addClass(couponWarning, 'warn');
        couponPaused = true;
        return false;
      }
    }

    function checkApplied() {
      if(query('.fek-coupon-code-applied').length!==0) {
        addClass(couponSubmit[0], 'active');
      }
    }


  };

  //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
  //$(document).ready(function() {

  document.addEventListener("DOMContentLoaded", function(event) {
     var trigger = query('.fek-cart-wrap');

     if(trigger.length) {
      var cart = new window.DS.cart();
     }


  });

  //});

})();
