(function() {
  'use strict';

  //base functions
  var base = Object.getOwnPropertyDescriptor(window.DS, 'base').value,
      query = base('query'),
      id = base('id'),
      cl = base('cl'),
      tag = base('tag'),
      elIndex = base('getElementIndex'),
      eAddAll = base('eAddAll'),
      eRmAll = base('eRmAll'),
      addClass = base('addClass'),
      removeClass = base('removeClass'),
      toggleClass = base('toggleClass'),
      closestParent = base('closestParent'),
      fillVideo = base('fillVideo'),
      getBackgroundImgURL = base('getBackgroundImgURL'),
      loadImage = base('loadImage'),
      createEvent = base('createEvent');

      window.DS.pressCarousel = function() {
        var self = this,
            pressCarousel = query('.hp-press-carousel'),
            slides = query('.slide'),
            slideIndex = 0,
            nextArrow = query('.next-arrow'),
            prevArrow = query('.prev-arrow'),
            dotContainer = query('.slide-dots'),
            slidePosition = {};

        init();

        function init(){
          _setupDots();
          slides.length < 5 && slides.length > 2 ? _setCarouselAlt() : _setCarousel();
          _buildCarousel();
          _setupMobilePressCarouselSwipes();
          _listen();
        }

        function _setCarousel(direction) {
          var currentSlides = query('.slide');
          _stripAllClass(currentSlides);
          var currentSlide = slides[_handleSlideIndex(slideIndex, slides)];
          var prevSlide = slides[_handleSlideIndex(slideIndex - 1, slides)];
          var nextSlide = slides[_handleSlideIndex(slideIndex + 1, slides)];
          var nextNextSlide = slides[_handleSlideIndex(slideIndex - 2, slides)];
          var prevPrevSlide = slides[_handleSlideIndex(slideIndex + 2, slides)];

          currentSlide.classList.add('current');
          prevSlide.classList.add('prev');
          nextSlide.classList.add('next');
          nextNextSlide.classList.add('prevprev');
          prevPrevSlide.classList.add('nextnext');

        }

        function _setCarouselAlt(){
          //this sets clones for when there is less than 5 slides
          var currentSlides = query('.slide');
          removeClass(currentSlides, 'current');
          removeClass(currentSlides, 'prev');
          removeClass(currentSlides, 'next');

          var currentSlide = currentSlides[_handleSlideIndex(slideIndex, currentSlides)];
          var prevSlide = currentSlides[_handleSlideIndex(slideIndex - 1, currentSlides)];
          var nextSlide = currentSlides[_handleSlideIndex(slideIndex + 1, currentSlides)];
          var nextNextSlide = currentSlides[_handleSlideIndex(slideIndex - 2, currentSlides)];
          var prevPrevSlide = currentSlides[_handleSlideIndex(slideIndex + 2, currentSlides)];

          currentSlide.classList.add('current');
          prevSlide.classList.add('prev');
          nextSlide.classList.add('next');
          _cloneSlides()
        }

        function _cloneSlides() {
          var currentSlides = query('.slide');
          var slideContainer = query('.slide-container');
          var nextNextSlide = currentSlides[_handleSlideIndex(slideIndex + 2, currentSlides)];
          var prevPrevSlide = currentSlides[_handleSlideIndex(slideIndex - 2, currentSlides)];
          if(nextNextSlide.classList.contains('next') || nextNextSlide.classList.contains('prevprev') || nextNextSlide.classList.contains('prev')){
            var cloneNextNextSlide = nextNextSlide.cloneNode(true);
              _stripAllClass(cloneNextNextSlide);
              addClass(cloneNextNextSlide, 'nextnext');

              slideContainer[0].append(cloneNextNextSlide);
          }  else {
            nextNextSlide.classList.add('nextnext');
          }

          if(prevPrevSlide.classList.contains('prev') || prevPrevSlide.classList.contains('nextnext') || prevPrevSlide.classList.contains('next')){

            var clonePrevPrevSlide = prevPrevSlide.cloneNode(true);
              _stripAllClass(clonePrevPrevSlide);
              addClass(clonePrevPrevSlide, 'prevprev');

              slideContainer[0].append(clonePrevPrevSlide);
          }  else {
            prevPrevSlide.classList.add('prevprev');
          }

          _setSlidePosition()
        }

        function _changeCarouselAlt(direction){
          var currentSlides = query('.slide');
          var slideContainer = query('.slide-container')[0];

          _stripAllClass(currentSlides);

          if(direction === 'next'){
            slideContainer.removeChild(slidePosition['-2'][0])
            addClass(slidePosition['-1'], 'prevprev');
            addClass(slidePosition['0'], 'prev');
            addClass(slidePosition['1'], 'current');
            addClass(slidePosition['2'], 'next');

            var clone = _cloneSingleSlide(_handleSlideIndex(slideIndex + 2, slides))

            _stripAllClass(clone)
            addClass(clone, "nextnext");
            slideContainer.append(clone);

          } else {
            slideContainer.removeChild(slidePosition['2'][0])
            addClass(slidePosition['-2'], 'prev');
            addClass(slidePosition['-1'], 'current');
            addClass(slidePosition['0'], 'next');
            addClass(slidePosition['1'], 'nextnext');

            var clone = _cloneSingleSlide(_handleSlideIndex(slideIndex - 2, slides))

            _stripAllClass(clone)
            addClass(clone, "prevprev");
            slideContainer.append(clone);
          }
          _setSlidePosition();
        }

        function _cloneSingleSlide(original){
          var slideClone = slides[original].cloneNode(true);
          return slideClone;
        }

        function _stripAllClass(element){
          removeClass(element,'current');
          removeClass(element,'next');
          removeClass(element,'nextnext');
          removeClass(element,'prev');
          removeClass(element,'prevprev');
        }

        function _setSlidePosition(direction){

          slidePosition["-2"] = query('.slide.prevprev');
          slidePosition["-1"] = query('.slide.prev');
          slidePosition["0"] = query('.slide.current');
          slidePosition["1"] = query('.slide.next');
          slidePosition["2"] = query('.slide.nextnext');
        }

        function _handleSlideIndex(index, slidesArray){
          if(index < 0){

            return slidesArray.length + index;
          } else if(index >= slidesArray.length) {
            return -(slidesArray.length - index);
          } else {
            return index;
          }
        }

        function _changeSlideIndex(index, direction){
          if(index > slides.length - 1) {
            slideIndex = 0;
          } else if(index < 0) {
            slideIndex = slides.length - 1;
          }
          _dotChange(slideIndex);
          slides.length < 5 && slides.length > 2 ? _changeCarouselAlt(direction) : _setCarousel();
        }

        function _buildCarousel(){

        }

        function _setupDots(){
          for (var i = 0; i < slides.length; i++) {
            var dotHtml = document.createElement('div');
            dotHtml.classList.add('slide-dot');
            if(i == 0) {
                dotHtml.classList.add('active');
            }
            dotContainer[0].append(dotHtml);
          }
        }

        function _dotChange(index){
          var slideDots = query('.slide-dot');
          removeClass(slideDots, 'active');
          slideDots[index].classList.add('active');
        }

        function _listen(){
          eAddAll(nextArrow, 'click', _nextSlide);
          eAddAll(prevArrow, 'click', _prevSlide);
          eAddAll(query('.slide-dot'), 'click', _clickDot);
        }

        function _clickDot(e){
          var dotIndex = elIndex(e.target);
          var indexDiff = dotIndex - slideIndex

          for (var i = 0; i < Math.abs(indexDiff); i++) {
            setTimeout(function(){

              if(indexDiff > 0){
                _nextSlide(e)
              } else if(indexDiff < 0){
                _prevSlide(e)
              }
            }, 400*i*.1)
          }

        }

        function _nextSlide(e){
          _changeSlideIndex(++slideIndex, "next")
        }

        function _prevSlide(e){
          _changeSlideIndex(--slideIndex, "prev")
        }

        function _setupMobilePressCarouselSwipes () {
          var _isTouch = ('ontouchstart' in window);
          if(_isTouch){

             var xTouchStart = 0;
             var xTouchDif = 0;
             var xTouchDifMax = 110;
             pressCarousel[0].addEventListener('touchstart', function(e) {
                 xTouchStart = e.targetTouches[0].pageX;
                 xTouchDif = 0;
                 //alert();
              }, true);
              pressCarousel[0].addEventListener('touchmove', function(e){
                 xTouchDif = xTouchStart-e.targetTouches[0].pageX;
              }, true);
              pressCarousel[0].addEventListener('touchend', function(e){
                  if(Math.abs(xTouchDif) >= xTouchDifMax){

                      if(xTouchDif < 0){
                          //alert('back');
                          //Go Back.
                          _prevSlide();
                      } else {
                          //alert('forward');
                          //Go Forward.
                          _nextSlide();
                      }
                  }
              }, true);


          }/* End Touch Detection */
        };

      }

    //$ using jQuery, pass jQuery to function above - (function($) {  - below - })(jQuery);
    //$(document).ready(function() {

    document.addEventListener("DOMContentLoaded", function(event) {
       var trigger = query('.hp-press-carousel');
        if(trigger.length) {
         console.log("press-carousel.js");
         var dsObject = new window.DS.pressCarousel();
        };

    });

    //});

})();
